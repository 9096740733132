import { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class NavMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: sessionStorage.getItem("selectedNavMenu"),
    };
  }

  render() {
    const selected = this.props.selected ? "selected" : "";

    return (
      <div className={"nav-menu " + selected} onClick={this.props.onClick}>
        <div className="icon">
          {this.props.iconIs === undefined && (
            <FontAwesomeIcon icon={this.props.selected ? this.props.iconSelected : this.props.icon} />
          )}
          {this.props.iconIs === "image" && <img src={this.props.icon} />}
          {/* Vísa aktivitetir hendir */}
          {
            //this.props.text === "Activity" &&
            parseInt(this.props.popUp ?? 0) > 0 ? <div className="popUp">{this.props.popUp}</div> : ""
          }
        </div>
        <div className="option-text">{this.props.text}</div>
      </div>
    );
  }
}

export default NavMenu;
