import { EAccessType, ERoleType } from "../PermCheck/PermCheck";

export type Route = {
  nav?: string; // partial-slug/key
  sidebar?: string; // Old feature that needs to be removed, but added in here to ensure we don't break anything.
  displayName?: string; // Name to display in visual navigation elements.
  value?: number;
  sections?: IRoutes; // Sections within route.
  noAuth?: boolean; // Flag that allows the user to navigate to this route without being logged in.
  checkType?: EAccessType | EAccessType[];
  checkId?: number | number[] | string | string[];
  checkMinimumRole?: ERoleType;
};

interface IRoutes {
  default: Route;
  [key: string]: Route;
}

// This array helps us initialize our route when deep linking

export const navigationElements: IRoutes = {
  /* Default */
  default: {
    nav: "home",
    sidebar: "",
    displayName: "Home",
  },

  /* Sections */
  home: {
    nav: "home",
    sidebar: "",
    displayName: "Home",
    sections: {
      // This should include the sections for our leaflets
      default: {},
    },
  },
  activity: {
    nav: "activity",
    sidebar: "",
    displayName: "Activity",
  },
  pages: {
    nav: "pages",
    sidebar: "",
    displayName: "Pages",
    sections: {
      default: {},
      id: {
        nav: "id",
        checkType: "superadmin",
      },
    },
    checkType: "sites",
    checkMinimumRole: "Publisher",
  },
  articles: {
    nav: "articles",
    sidebar: "",
    displayName: "Articles",
    checkType: "articleTypes",
    checkMinimumRole: "Publisher",
  },
  media: {
    nav: "media",
    sidebar: "list",
    displayName: "Media",
    checkMinimumRole: "Collaborator",
    checkType: ["sites"],
  },
  whatson: {
    nav: "whatson",
    sidebar: "whatson",
    displayName: "What's On",
    sections: {
      /* Default */
      default: {
        nav: "events",
        displayName: "Events",
        sections: {
          default: {},
          add: {
            nav: "add",
          },
        },
      },

      /* Sections */
      events: {
        nav: "events",
        displayName: "Events",
        sections: {
          default: {},
          add: {
            nav: "add",
          },
        },
      },
      places: {
        nav: "places",
        displayName: "Places",
        sections: {
          default: {},
          add: {
            nav: "add",
            // checkType: "superadmin",
          },
        },
      },
      tours: {
        nav: "tours",
        displayName: "Tours",
        sections: {
          default: {},
          add: {
            nav: "add",
          },
        },
      },
      tour_providers: {
        nav: "tour_providers",
        displayName: "Tour Providers",
        sections: {
          default: {},
          add: {
            nav: "add",
          },
        },
      },
      hiking: {
        nav: "hiking",
        displayName: "Hiking",
        checkType: "specifics",
        checkId: "hiking",
        sections: {
          default: {},
          add: {
            nav: "add",
          },
        },
      },
    },
  },
  users: {
    nav: "users",
    sidebar: "",
    displayName: "Users",
  },
  analytics: {
    nav: "analytics",
    sidebar: "",
    displayName: "Analytics",
  },
  collaboration: {
    nav: "collaboration",
    sidebar: "",
    displayName: "Collaboration",
  },
  shop: {
    nav: "shop",
    sidebar: "",
    displayName: "Shop",
  },
  admin: {
    nav: "admin",
    sidebar: "",
    displayName: "Admin",
    sections: {
      /* Default */
      default: {
        nav: "sites",
        displayName: "Sites",
      },

      /* Sections */
      sites: {
        nav: "sites",
        displayName: "Sites",
      },
      infoscreens: {
        nav: "infoscreens",
        displayName: "Infoscreens",
      },
      amenities: {
        nav: "amenities",
        displayName: "Amenities (places)",
        sections: {
          /* Default */
          default: {
            nav: "add",
          },

          /* Sections */
          add: {
            nav: "add",
          },
          edit: {
            value: 0,
          },
        },
      },
    },
  },
  help: {
    nav: "help",
    sidebar: "",
    displayName: "Help",
  },
  login: {
    nav: "login",
    noAuth: true,
    displayName: "Login",
  },
  submit: {
    nav: "submit",
    noAuth: true,
    displayName: "Submit",
    sections: {
      /* Default */
      default: {
        nav: "",
        displayName: "",
      },

      /* Sections */
      event: {
        nav: "event",
        displayName: "Event",
        sections: {
          default: {
            nav: "create",
          },

          create: {
            nav: "create",
          },
        },
      },
      place: {
        nav: "place",
        displayName: "Place",
        sections: {
          default: {
            nav: "create",
          },

          create: {
            nav: "create",
          },

          register: {
            nav: "register",
          },
        },
      },
    },
  },
  register: {
    nav: "register",
    noAuth: true,
    displayName: "Register",
    sections: {
      /* Default */
      default: {
        nav: "",
        displayName: "",
      },

      verify_email: {
        nav: "verify_email",
        displayName: "Verify email",
      },
    },
  },
  forgot_password: {
    nav: "forgot_password",
    noAuth: true,
    displayName: "Forgot password",
  },
  newPassword: {
    nav: "newPassword",
    noAuth: true,
    displayName: "New password",
  },
  change: {
    nav: "change",
    noAuth: true,
    displayName: "Change",
    sections: {
      /* Default */
      default: {
        nav: "",
        displayName: "",
      },

      /* Sections */
      event: {
        nav: "event",
        displayName: "Event",
      },
    },
  },
};

export const outer_routes: (string | undefined)[] = Object.fromEntries(
  Object.entries(navigationElements).filter((element) => element[1]?.noAuth)
) as any;
