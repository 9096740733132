import axios from "axios";
import { useEffect, useRef } from "react";
import env from "../environment.json";
import globalObject from "./globalVariables";

function sortListFunc(contents, sortingBy, sortingByArr, otherWay = false) {
  if (!contents) return contents;

  contents.sort((a, b) => {
    var aPart = a;
    var bPart = b;

    sortingByArr.forEach((part) => {
      if (aPart !== undefined) aPart = aPart[part];
      if (bPart !== undefined) bPart = bPart[part];
    });

    if (sortingBy === "fileSize") {
      aPart = parseInt(aPart);
      bPart = parseInt(bPart);
      if (!otherWay) return aPart > bPart ? 1 : -1;
      else return aPart < bPart ? 1 : -1;
    }

    if (
      sortingBy === "date" ||
      sortingBy === "last_login_date" ||
      sortingBy === "regdate" ||
      sortingBy === "create_date"
    ) {
      aPart = new Date(aPart);
      bPart = new Date(bPart);
    }

    if (aPart === undefined || aPart === null) aPart = "";
    if (bPart === undefined || bPart === null) bPart = "";

    if (typeof aPart === "string" || aPart instanceof String) {
      if (!otherWay) return aPart.toLowerCase() > bPart.toLowerCase() ? 1 : -1;
      else return aPart.toLowerCase() < bPart.toLowerCase() ? 1 : -1;
    } else {
      if (!otherWay) return aPart < bPart ? 1 : -1;
      else return aPart > bPart ? 1 : -1;
    }
  });
  return contents;
}

export function compare(a, b) {
  if (parseInt(a.order_value) < parseInt(b.order_value)) {
    return -1;
  }
  if (parseInt(a.order_value) > parseInt(b.order_value)) {
    return 1;
  }
  return 0;
}

//Will sort a list
export function sortList(
  sortingBy,
  contents,
  isSame,
  reverseSortAsFunc = false,
  otherWay = true
) {
  if (sortingBy !== undefined) {
    var sortingByArr = sortingBy.split(".");
    if (isSame) {
      if (!reverseSortAsFunc) {
        contents.reverse();
      } else {
        contents = sortListFunc(contents, sortingBy, sortingByArr, !otherWay);
      }
      return contents;
    } else {
      // contents.sort((a, b) => {
      //   var aPart = a;
      //   var bPart = b;
      //   sortingByArr.forEach((part) => {
      //     if (aPart !== undefined) aPart = aPart[part];
      //     if (bPart !== undefined) bPart = bPart[part];
      //   });

      //   if (sortingBy === "fileSize") {
      //     aPart = parseInt(aPart);
      //     bPart = parseInt(bPart);
      //     return aPart > bPart ? 1 : -1;
      //   }

      //   if (
      //     sortingBy === "date" ||
      //     sortingBy === "last_login_date" ||
      //     sortingBy === "regdate"
      //   ) {
      //     aPart = new Date(aPart);
      //     bPart = new Date(bPart);
      //   }

      //   if (aPart === undefined || aPart === null) aPart = "";
      //   if (bPart === undefined || bPart === null) bPart = "";

      //   if (typeof aPart === "string" || aPart instanceof String) {
      //     return aPart.toLowerCase() > bPart.toLowerCase() ? 1 : -1;
      //   } else return aPart < bPart ? 1 : -1;
      // });
      contents = sortListFunc(contents, sortingBy, sortingByArr, !otherWay);
      return contents;
    }
  }
}

export function whatsonContentPush(e) {
  return {
    id: e.id,
    mainImage: {
      id: e.id_media,
      file_name: e.file_name,
      extension: e.file_extension,
      alt: e.alt,
    },
    title: e.headline,
    author: {
      firstname: e.firstname,
      lastname: e.lastname,
      fullname: e.firstname + " " + e.lastname,
    },
    region: e.region_name,
    location: e.location,
    date: e.create_date,
    currentStatus: e.current_status,
    approvalStatus: e.approval_status,
    submittedChanges: e.submittedChangeId !== null && e.submittedChangeId !== undefined,
    current_status: e.current_status,
    approval_status: e.approval_status,
    languages: e.languages,
  };
}

//Returns new list after search in list with objects
export function searchFilter(list, searchValue) {
  var returnList = [];
  const filter = searchValue.toLowerCase();

  if (filter === "") return list;
  else
    list.forEach((element, i) => {
      if (searchJson(element, searchValue.toLowerCase())) {
        returnList.push(element);
      }
    });

  return returnList;
}

//Returns new list after search
export function filterDateInterval(list, startDate, endDate) {
  var returnList = [];
  if (startDate < endDate) {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);
    list.forEach((element) => {
      let regDate = new Date(element.regdate);
      regDate.setHours(0, 0, 0, 0);
      if (regDate.valueOf() >= startDate.valueOf() && regDate.valueOf() <= endDate.valueOf()) {
        returnList.push(element);
      }
    });
  } else {
    returnList = list;
  }

  return returnList;
}

//Is used by search filter
export function searchJson(json, filter, excludeKeys = []) {
  let safeFilter = filter.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  for (var key in json) {
    if (excludeKeys.includes(key)) continue;

    if (typeof json[key] === "string" || json[key] instanceof String) {
      if (json[key].toLowerCase().search(safeFilter.toLowerCase()) !== -1) {
        return true;
      }
    } else {
      if (typeof json[key] === "object" || json[key] instanceof Object);
      if (searchJson(json[key], safeFilter, excludeKeys)) {
        return true;
      }
    }
  }
  return false;
}

//Makes first letter big and next small
export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

//Shows date a bit smarter
export function showDate(date) {
  const dataDate = new Date(date);
  const currentDate = new Date();
  //currentDate = currentDate.toUTCString('UTC-3');

  const diffMill = Math.abs(dataDate - currentDate);
  const diffSecs = Math.floor(diffMill / 1000);
  const diffMinutes = Math.floor(diffSecs / 60);
  const diffHours = Math.floor(diffMinutes / 60);

  if (diffSecs < 60) {
    return diffSecs + (diffSecs === 1 ? " second ago" : " seconds ago");
  } else if (diffMinutes < 60) {
    return diffMinutes + (diffMinutes === 1 ? " minute ago" : " minutes ago");
  } else if (diffHours < 24) {
    return diffHours + (diffHours === 1 ? " hour ago" : " hours ago");
  }

  const months = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  return (
    dataDate.getDate() +
    "-" +
    months[dataDate.getMonth()] +
    "-" +
    dataDate.getFullYear() +
    " " +
    (dataDate.getHours() < 10 ? "0" + dataDate.getHours() : dataDate.getHours()) +
    ":" +
    (dataDate.getMinutes() < 10 ? "0" + dataDate.getMinutes() : dataDate.getMinutes())
  );
}

export function removeTags(str) {
  if (str === null || str === "" || str === undefined) return "";
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

export function slugify(text) {
  // return text.toString().toLowerCase()
  //   .replace(/\s+/g, '-')           // Replace spaces with -
  //   .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
  //   .replace(/\-\-+/g, '-')         // Replace multiple - with single -
  //   .replace(/^-+/, '')             // Trim - from start of text
  //   .replace(/-+$/, '');            // Trim - from end of text

  text = text.replace(/^\s+|\s+$/g, ""); // trim
  text = text.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâæåðèéëêìíïîòóöôùúüûñçýø·/_,:;";
  var to = "aaaaaadeeeeiiiioooouuuuncyo------";
  for (var i = 0, l = from.length; i < l; i++) {
    text = text.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  text = text
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return text;
}

export function allObjTrue(obj) {
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "object") {
      obj[key] = allObjTrue(value);
    } else {
      obj[key] = true;
    }
  }
  return obj;
}

export function uploadMedia(e, siteId, config = {}, afterFunc = (response) => void 0) {
  var formData = new FormData();

  e.forEach((element) => {
    formData.append("fileToUpload[]", element, element.name);
  });
  formData.append("siteId", siteId);
  formData.append("userId", sessionStorage.getItem("vfiUser"));
  axios
    .post(env.protocol + env.env + "/api/secured/UploadMediaFile", formData, config)
    .then((res) => {
      afterFunc(res);
    })
    .catch((err) => {
      // console.error(err);
      console.log(err);
    });
}

export function pushMedia(e) {
  return {
    id: e.id,
    name: e.headline,
    description: e.description,
    comment: e.comment,
    fileName: e.name,
    author: e.author_info
      ? {
          ...e.author_info,
          fullName: e.author_info.firstname + " " + e.author_info.lastname,
        }
      : {},
    locations: e.sites,
    fileSize: e.file_size,
    fileExtension: e.file_extension,
    fileDimensions: e.file_dimensions_x + " x " + e.file_dimensions_y + " px",
    date: e.fo_upload_date,
    selected: false,
    sortedBy: "",
    alt: e.alt,
    file_name: e.file_name,
    hearted: e.hearted,
    sites: e.sites ? e.sites : [],
    languages: e.languages,
    medium: e.medium,
  };
}
export function orderPage(data, afterFunc = () => void 0) {
  axios
    .post(env.protocol + env.env + "/api/secured/pages/OrderPage", {
      pages: data.pages,
      siteId: data.siteId,
      id: data.id,
      user: sessionStorage.getItem("vfiUser"),
    })
    .then((response) => {
      afterFunc(response);
    })
    .catch((error) => console.error(error));
}

export function forceCheckLanguage(pageLanguages) {
  if (pageLanguages["english"]) {
    return pageLanguages["english"];
  }
  if (pageLanguages["en"]) {
    return pageLanguages["en"];
  }
  if (pageLanguages["faroese"]) {
    return pageLanguages["faroese"];
  }
  if (pageLanguages["fo"]) {
    return pageLanguages["fo"];
  }
  if (pageLanguages["danish"]) {
    return pageLanguages["danish"];
  }
  if (pageLanguages["dk"]) {
    return pageLanguages["dk"];
  }
  if (Object.keys(pageLanguages).length > 0) {
    if (pageLanguages[Object.keys(pageLanguages)[0]]) {
      return pageLanguages[Object.keys(pageLanguages)[0]];
    }
  }
  return false;
}

export function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}
export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}
export function getAvailableLanguages() {
  return axios
    .get(env.protocol + env.env + "/api/public/pages/GetAllAvailableLanguages.php")
    .then((response) => {
      return [...response.data]
        .sort((a, b) => {
          return a.order_by > b.order_by;
        })
        .map((e) => {
          return { label: e.short.toUpperCase(), value: e.what_language };
        });
    })
    .catch((error) => {
      console.error(error);
    });
}
export function getEditPage(id, isShared) {
  return axios
    .post(env.protocol + env.env + "/api/public/pages/GetPage.php?update=true&shared=" + (isShared ? "1" : "0"), {
      user: sessionStorage.getItem("vfiUser"),
      page: id,
      // isShared: isShared
    })
    .then((response) => {
      const data = response.data;
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
}
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

let source;
export function getUsers(site, callback = () => void 0) {
  if (!globalObject.vfiUser) globalObject.vfiUser = {};
  if (!globalObject.vfiUser.profession) {
    globalObject.vfiUser.profession = "";

    source = axios.CancelToken.source();
    axios
      .post(
        env.protocol + env.env + "/api/secured/GetUsers",
        {
          userId: sessionStorage.getItem("vfiUser"),
        },
        {
          cancelToken: source.token,
        }
      )
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // console.log("Request canceled", error.message);
        } else {
          console.log(error);
        }
      });
  }
}

export function cancelGetUsers() {
  if (source) {
    source.cancel("Operation canceled by the user.");
  }
}

const findChild = (entireObj, keyToFind, valToFind, alsoIncluded = undefined) => {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (
      nestedValue &&
      nestedValue[keyToFind] === valToFind &&
      (alsoIncluded !== undefined ? nestedValue[alsoIncluded] : true)
    ) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
};

export function testImage(url, timeoutT) {
  return new Promise(function (resolve, reject) {
    var timeout = timeoutT || 5000;
    var timer,
      img = new Image();
    img.onerror = img.onabort = function () {
      clearTimeout(timer);
      // reject("error");
      resolve(false);
    };
    img.onload = function () {
      clearTimeout(timer);
      // resolve("success");
      resolve(true);
    };
    timer = setTimeout(function () {
      // reset .src to invalid URL so it stops previous
      // loading, but doesn't trigger new load
      img.src = "//!!!!/test.jpg";
      reject("timeout");
    }, timeout);
    img.src = url;
  });
}

export function countryCodeReturn(c) {
  if (c) {
    return { label: `${c.name} (+${c.dial_code})`, value: c.dial_code };
  } else {
    return undefined;
  }
}

export function twoDigits(e) {
  const RxFinalForm = /^(?:\d+(?:\.\d{0,2})?|\.\d{1,2})$/;
  const RxRmvInvalid = /[^\d.]+/g;
  const RxPartialForm = /^(\d+(?:\.\d{0,2})?|\.\d{0,2})?.*?$/;

  var ob = e.target;
  var sContent = ob.value;

  if (!RxFinalForm.test(e.target.value)) {
    sContent = sContent.replace(",", ".");
    sContent = sContent.replace(RxRmvInvalid, "");
    sContent = sContent.replace(RxPartialForm, "$1");

    if (ob.value !== sContent) return sContent;
  }
  return ob.value;
}

const helperFunctions = {
  sortList,
  whatsonContentPush,
  searchFilter,
  searchJson,
  capitalize,
  showDate,
  filterDateInterval,
  removeTags,
  slugify,
  allObjTrue,
  pushMedia,
  forceCheckLanguage,
  useEventListener,
  orderPage,
  array_move,
  getAvailableLanguages,
  getEditPage,
  usePrevious,
  uploadMedia,
  getUsers,
  findChild,
  testImage,
  countryCodeReturn,
};

export default helperFunctions;
