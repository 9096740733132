import { useEffect, useState } from "react";
import { IInformation } from "../../submit-types/submit-event/SubmitEvent";
import styles from "./AboutYou.module.scss";
import validateForm from "../utils/form-validation";
import { useRouterStore } from "src/Components/Router/routerStore";
import VfiInputText from "src/assets/VfiInputText/VfiInputText";
import VfiInput from "src/assets/VfiInput/VfiInput";
import VfiButton from "src/assets/VfiButton/VfiButton";
import TermsOfService from "../terms-of-service/TermsOfService";
import VfiLogo from "../../../route-components/VfiLogo/VfiLogo";

interface Props {
  information: IInformation;
  setInformation: (info: IInformation) => void;
  setForward: (bool: boolean) => void;
}

const form_inputs = {
  name: {
    message: "",
    valid: true,
  },
  email: {
    message: "",
    valid: true,
  },
};

export default function AboutYou({ information, setInformation, setForward }: Props) {
  const { navByLink, get_parameters } = useRouterStore();

  const [errors, setErrors] = useState<any>({
    ...form_inputs,
    terms_of_service: {
      message: "",
      valid: true,
    },
  });

  const [terms_of_service, setTermsOfService] = useState<boolean>(false);

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <VfiLogo className={styles.logo} />

        {/* Title */}
        <h3>A bit about you</h3>

        {/* Inputs */}
        {Object.keys(form_inputs).map((input) => {
          return (
            <VfiInput
              key={input}
              className={`${styles.input} ${styles[`${input}`]}`}
              placeholder={`Enter ${input}`}
              value={(information as any)?.[input]}
              label={`What is your ${input}?`}
              error={!errors[input].valid ? errors[input].message : ""}
              onChange={(e) => {
                setInformation({
                  ...information,
                  [input]: e.target.value,
                });
                setErrors({
                  ...errors,
                  [input]: {
                    message: "",
                    valid: true,
                  },
                });
              }}
              type="text"
            />
          );
        })}

        {/* Terms of Condition */}
        <TermsOfService
          value={terms_of_service}
          onChange={(e) => {
            setTermsOfService(e.target.checked);
            setInformation({ ...information, terms: e.target.checked });
            setErrors({
              ...errors,
              terms_of_service: {
                message: "",
                valid: true,
              },
            });
          }}
          error={errors.terms_of_service}
        />

        {/* Button */}
        <VfiButton
          onClick={() => {
            let validation = validateForm(information);
            setErrors({
              ...errors,
              ...validation.errors,
              ...(!terms_of_service
                ? {
                    terms_of_service: {
                      message: "You need to accept the terms of conditions",
                      valid: false,
                    },
                  }
                : {
                    terms_of_service: {
                      message: "",
                      valid: true,
                    },
                  }),
            });

            if (!terms_of_service) console.log("You need to accept our terms of service");

            if (validation.valid && terms_of_service) {
              setForward(true);
              navByLink(`submit/event/create`, {
                returnUrl: get_parameters?.returnUrl,
              });
            }
          }}
        >
          Create an event
        </VfiButton>
      </div>
    </div>
  );
}
