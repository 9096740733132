import React, { Component } from "react";
import { faTimes, faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./QuestionInfo.scss";

class QuestionInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
    };

    this.wrapperRef = React.createRef();
    //this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.closeTab();
    }
  }

  openTab() {
    this.setState({ hidden: false });
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  closeTab() {
    this.setState({ hidden: true });
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    const questionBoxClassName = this.state.hidden ? "question-box hidden" : "question-box";

    return (
      <span className="question-info" ref={this.wrapperRef}>
        <FontAwesomeIcon
          icon={this.props.otherIcon || faQuestionCircle}
          onClick={() => {
            this.openTab();
          }}
        />
        <div className={questionBoxClassName}>
          <div
            className="close-tab"
            onClick={() => {
              this.closeTab();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          {typeof this.props.children === "string" ? (
            <div className="information" dangerouslySetInnerHTML={{ __html: this.props.children }}></div>
          ) : (
            <div className="information">{this.props.children}</div>
          )}
          <div className="point-up"></div>
        </div>
      </span>
    );
  }
}
export default QuestionInfo;
