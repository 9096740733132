import Verify from "../../../../assets/VerifyMethods";

/**
 * Validate that the information step has been passed
 *
 * @param 		{bool} 					showEn									Whether to show english
 * @param 		{bool} 					showFo 									Whether to show faroese
 * @param 		{object|null} 	beenEdit 								Which fields have been edited
 * 																										null if not relevant
 * @param 		{object} 				newTourOperator 				New tour operator object
 *
 * @returns 	{bool} 														Whether all fields pass validation
 *
 * @author 					Pætur Mortensen
 */
export function informationPassed(showEn, showFo, beenEdit, newTourOperator) {
  // Check whether there have been any edits in the information step
  const anyEdits =
    beenEdit === null ||
    [
      beenEdit.textEn.headline,
      beenEdit.textEn.shortDescription,
      beenEdit.textEn.description,
      beenEdit.textFo.headline,
      beenEdit.textFo.shortDescription,
      beenEdit.textFo.description,
    ].includes(true);

  // If no edits have been made...
  if (!anyEdits) {
    // Nothing to validate, return true
    return true;
  }

  /**
   * Validate the headline
   *
   * @param 		{string} 	headline 								Headline string to validate
   *
   * @returns 	{bool} 														Whether headline validates
   *
   * @author 					Pætur Mortensen
   */
  function validate_headline(headline) {
    return Verify.notEmpty(headline) === "" && Verify.minChars(headline, 3) === "";
  }

  /**
   * Validate the short description
   *
   * @param 		{string} 	desc  									Short description string to validate
   *
   * @returns 	{bool} 														Whether short description validates
   *
   * @author 					Pætur Mortensen
   */
  function validate_short_desc(desc) {
    return Verify.notEmpty(desc) === "" && Verify.minChars(desc, 10) === "";
  }

  /**
   * Validate description
   *
   * @param 		{string} 	desc 											Description string to validate
   *
   * @returns 	{bool} 															Whether description validates
   *
   * @author 					Pætur Mortensen
   */
  function validate_desc(desc) {
    return Verify.notEmpty(desc) === "" && Verify.minChars(desc, 70) === "";
  }

  // If we are to show english...
  if (showEn) {
    if (
      !(
        validate_headline(newTourOperator.textEn.headline) &&
        validate_short_desc(newTourOperator.textEn.shortDescription) &&
        validate_desc(newTourOperator.textEn.description)
      )
    ) {
      return false;
    }
  }

  // If we are to show faroese...
  if (showFo) {
    if (
      !(
        validate_headline(newTourOperator.textFo.headline) &&
        validate_short_desc(newTourOperator.textFo.shortDescription) &&
        validate_desc(newTourOperator.textFo.description)
      )
    ) {
      return false;
    }
  }

  // If neither show english nor show faroese is checked...
  if (!showEn && !showFo) {
    return false;
  }

  return true;
}

/**
 * Validate that the media step has been passed
 *
 * @param 		{bool} 					showEn 									Whether to show english
 * @param 		{bool} 					showFo 									Whether to show faroese
 * @param 		{object|null} 	beenEdit	 							Which fields have been edited
 * 																										null if not relevant
 * @param 		{object} 				newTourOperator 				New tour operator object
 *
 * @returns 	{bool} 																	Whether the media step has passed
 *
 * @author 					Pætur Mortensen
 */
export function mediaPassed(showEn, showFo, beenEdit, newTourOperator) {
  // If images have been edited or if beenEdit should be ignored...
  // if (beenEdit === null || beenEdit.images.mainImages) {
  //   const mainImages = newTourOperator.images.mainImages;
  //   for (let i = 0; i < mainImages.length; i++)
  //     if (
  //       (showEn &&
  //         (mainImages[i].enText === undefined ||
  //           Verify.notEmpty(mainImages[i].enText) !== "")) ||
  //       (showFo &&
  //         (mainImages[i].foText === undefined ||
  //           Verify.notEmpty(mainImages[i].foText) !== ""))
  //     ) {
  //       return false;
  //     }
  // }

  // If instagram has been edited and is active but text inputs fail validation)...
  const beenEditInsta = beenEdit === null || beenEdit.instagram;
  const instagram = newTourOperator.instagram;
  if (
    (Verify.notEmpty(instagram.hashtag) !== "" ||
      Verify.notEmpty(instagram.profile) !== "" ||
      Verify.isInstagramLink(instagram.profile) !== "") &&
    beenEditInsta &&
    instagram.active
  ) {
    return false;
  }

  // If instagram has been edited and is active...
  if ((beenEditInsta || beenEdit === null) && instagram.active) {
    const instaImages = instagram.images;
    // If there are no insta images...
    if (instaImages.length === 0) return false;
    // For each insta image...
    for (let i = 0; i < instaImages.length; i++) {
      // If images has not been set....
      if (
        (instaImages[i].media.id === undefined && instaImages[i].media.src === undefined) ||
        Verify.notEmpty(instaImages[i].instaLink) !== "" ||
        Verify.isInstagramLink(instaImages[i].instaLink) !== ""
      ) {
        return false;
      }
    }
  }

  // Validation passed, return TRUE
  return true;
}

/**
 * Validate that all fields on the location step have passed
 *
 * Location step also includes contact information
 *
 * @param 		{object|null} 	beenEdit 							Object with information on what has been edited
 * 																									null if beenEdit should be ignored
 * @param 		{object} 				newTourOperator 			The new tour operator object
 *
 * @returns 	{bool} 																Whether the step has passed validation
 *
 * @author 					Pætur Mortensen
 */
export function locationPassed(beenEdit, newTourOperator) {
  // If address has been edited...
  if (beenEdit === null || beenEdit.address) {
    const address = newTourOperator.address;
    // If address is not undefined....
    if (address !== undefined) {
      // If address name is empty, mapCenter is not set,
      // region is not set, and zip is not set or 0...
      if (
        Verify.notEmpty(address.name) !== "" ||
        address.mapMarker === undefined ||
        address.region === undefined ||
        address.zip === undefined ||
        address.zip === 0
      ) {
        return false;
      }
    } else {
      // Address is undefined, return FALSE
      return false;
    }
  }

  // If any contact information has been edited..
  const contEdited = beenEdit === null || beenEdit.contactDetails;
  if ([contEdited.website, contEdited.phone, contEdited.email].includes(true)) {
    const contact = newTourOperator.contactDetails;

    // Phone number and email are required, validate these
    if (
      !(
        Verify.notEmpty(contact.phone) === "" &&
        Verify.notEmpty(contact.email) === "" &&
        Verify.validEmail(contact.email) === ""
      )
    ) {
      return false;
    }
  }

  return true;
}
