import { Component } from "react";
import Button from "../../../../assets/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowUpArrowDown, faEllipsisVertical, faTrash, faPen } from "@fortawesome/pro-regular-svg-icons";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import env from "../../../../environment.json";
import TeamMemberOverlay from "./TeamMemberOverlay";
import { GlobalToolTipController } from "src/Components/ToolTips/GlobalToolTip";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";

/**
 * Team form step for tour operator
 *
 * Here we set the team members for the tour operator
 *
 * @author 					Pætur Mortensen
 */
class Team extends Component {
  /**
   * props:
   * @property 		{object} 		newTourOperator 					Tour operator information
   * @property 		{object} 		language 									Language object with strings
   * @property 		{function} 	saveChanges 							Save changes made here
   * @property 		{function} 	setSiteRef 								Set site reference
   * @property 		{function} 	openOverlay 							Open overlay (for member editing)
   * @property		{component} whatsonNavigation 				WhatsonNavigation component
   *
   * @author 					Pætur Mortensen
   */
  constructor(props) {
    super(props);

    // Set the initial state
    this.state = {
      // Whether the member overlay CP is open (in table)
      memberCPOpen: null,
      // Whether the edit member overlay is open
      memberOverlayOpen: false,
      // ID of member being edited
      editingMemberID: null,
      // Get members from the outer newTourOperator object
      members: this.props.newTourOperator.team.members,
    };
  }

  /**
   * Sort members by name
   *
   * @param 	{array} 	members 									Team members to sort
   * @param 	{bool} 		asc 											Whether to sort in ascending alpha order
   *
   * @returns 	{array} 													Sorted array of members
   *
   * @author 					Pætur Mortensen
   */
  sort_members(members, asc) {
    // Return sorted array
    return members.sort((a, b) => a.profileName.localeCompare(b.profileName) * (asc ? 1 : -1));
  }

  /**
   * Close the member editing overlay
   *
   * @author 					Pætur Mortensen
   */
  close_member_overlay() {
    this.setState({ memberOverlayOpen: false, editingMemberID: null });
  }

  /**
   * Toggle the sort order of the members table
   *
   * Reverse ASC/DESC sorting order by member name
   *
   * @author 					Pætur Mortensen
   */
  toggle_members_table_sort() {
    // Get reverse of current sort order
    const asc = !this.state.team.membersSortAsc;

    // Sort members and set new states
    this.setState({
      team: {
        ...this.state.team,
        membersSortAsc: asc,
        members: this.sort_members([...this.props.newTourOperator.team.members], asc),
      },
    });
  }

  /**
   * Toggle the member CP
   *
   * Open for the member or close if already open
   *
   * @param 	{int} 	memberID 									ID of member for CP
   *
   * @author 					Pætur Mortensen
   */
  toggle_member_cp(memberID) {
    let memberCPOpen = memberID;
    // If the CP is already open for this member, close it
    if (this.state.memberCPOpen === memberID) {
      memberCPOpen = null;
    }

    // Set state with closed member CP
    this.setState({ memberCPOpen });
  }

  /**
   * Open the "add member" overlay
   *
   * @author 					Pætur Mortensen
   */
  add_member() {
    // Member overlay should be open, and no member ID supplied for editing
    this.setState({ memberOverlayOpen: true, editingMemberID: null });
  }

  /**
   * Edit a member
   *
   * Opens the overlay for member editing
   *
   * @param 	{int} 	memberID 								ID of member to edit
   *
   * @author 					Pætur Mortensen
   */
  edit_member(memberID) {
    // Set state and open the overlay on update
    this.setState({ memberOverlayOpen: true, editingMemberID: memberID });
  }

  /**
   * Delete a member from the team
   *
   * @param 	{int} 	memberID 							ID of member to delete
   *
   * @author 					Pætur Mortensen
   */
  delete_member(memberID) {
    this.props.newTourOperator.team.members.splice(
      this.props.newTourOperator.team.members.findIndex((member) => member.memberID === memberID),
      1
    );

    this.props.saveChanges();
  }

  /**
   * Render the team members table
   *
   * @returns 	{jsx} 										Team members table
   *
   * @author 					Pætur Mortensen
   */
  render_team_members_table() {
    /**
     * Render the member CP
     *
     * This function renders the member table overlay CP with "edit" and "delete member" buttons
     *
     * @param 		{int} 	memberID 							ID of member for CP
     *
     * @returns 	{jsx} 												The member CP overlay element
     *
     * @author 					Pætur Mortensen
     */
    const render_member_cp = (memberID) => {
      return (
        <div className="member-cp">
          <div
            onClick={() => {
              this.edit_member(memberID);
            }}
          >
            <FontAwesomeIcon icon={faPen} />
            {this.props.language.team.edit_member}
          </div>
          <hr />
          <div
            className="delete-member"
            onClick={() => {
              this.delete_member(memberID);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
            {this.props.language.team.delete_member}
          </div>
        </div>
      );
    };

    return (
      <table className="team-members-table">
        <thead>
          <tr>
            <th>
              {this.props.language.team.name}
              {
                <FontAwesomeIcon
                  className="sort"
                  onClick={() => {
                    this.toggle_members_table_sort();
                  }}
                  icon={faArrowUpArrowDown}
                />
              }
            </th>
            <th>{this.props.language.team.profession}</th>
          </tr>
        </thead>
        <tbody>
          {this.props.newTourOperator.team.members.map((member, idx) => {
            const memberID = member.memberID || idx;
            return (
              <tr key={memberID}>
                <td>
                  {member.imgFileName ? (
                    <img
                      className="profile-img"
                      src={
                        env.protocol + env.env + "/uploads/" + member.imgFileName + "_thumbnail." + member.imgFileExt
                      }
                      alt="user-img"
                    />
                  ) : (
                    <FontAwesomeIcon className="profile-img" icon={faUserCircle} />
                  )}
                  {member.profileName}
                </td>
                <td>
                  {member.role}
                  <div
                    onClick={() => {
                      this.toggle_member_cp(memberID);
                    }}
                    className="member-edit-btn"
                  >
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                    {this.state.memberCPOpen === memberID && render_member_cp(memberID)}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <div className="new-content team-section">
        {this.props.whatsonNavigation()}

        <h1>{this.props.language.team.header}</h1>
        <div className="with-tooltip-2">
          {this.props.language.team.sub_text}
          <GlobalToolTipController
            solid
            title={""}
            className={"tootlip-icon"}
            priority={"bottom,right,top,left"}
            toolTipElements={
              <div className={"tooltip-card"}>
                Let your audience know they'll be in good hands by adding your team members to the tours.
              </div>
            }
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </GlobalToolTipController>
        </div>

        <Button
          type="black"
          size="large"
          rounded
          icon={<FontAwesomeIcon className="plus-icon" icon={faPlus} />}
          iconPos="right"
          onClick={() => {
            this.add_member();
          }}
          className="add-member-btn"
        >
          {this.props.language.team.add_team_member}
        </Button>

        {this.render_team_members_table()}
        {this.state.memberOverlayOpen && (
          <TeamMemberOverlay
            newTourOperator={this.props.newTourOperator}
            language={this.props.language.team}
            memberID={this.state.editingMemberID}
            close={() => {
              this.close_member_overlay();
            }}
            setSiteRef={this.props.setSiteRef}
            openOverlay={this.props.openOverlay}
            saveChanges={this.props.saveChanges}
          />
        )}
      </div>
    );
  }
}

export default Team;
