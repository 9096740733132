import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAlt } from "@fortawesome/pro-regular-svg-icons";
import { faSortAlt as faSortAltSorted } from "@fortawesome/pro-duotone-svg-icons";
import VfiCheckbox from "../../../../assets/VfiCheckbox/VfiCheckbox";
import IsArticleContent from "./IsArticleContent";
import { GlobalToolTipController } from "../../../ToolTips/GlobalToolTip";

class IsArticleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedBy: "date",
    };

    // this.language = require("./language.json");
    // this.language = this.language[this.props.language];
  }

  render() {
    let returnList = this.props.list;

    return (
      <div className="whatson-list">
        {/* {console.log(this.props)} */}
        <table className="whatson-table">
          <thead>
            <tr>
              <th
                className={this.state.sortedBy === "title" ? "title sorted-by" : "title"}
                onClick={() => {
                  this.props.sortList("languages.en.title");
                  let reverse = false;
                  if (this.state.sortedBy === "title" && this.state.reverse === false) reverse = true;
                  this.setState({ sortedBy: "title", reverse: reverse });
                }}
              >
                {/* {this.language.table_head.title} */}
                <GlobalToolTipController
                  offset={{ x: 15, y: 30 }}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Title</div>}
                >
                  Title{" "}
                  <FontAwesomeIcon
                    icon={this.state.sortedBy === "title" ? faSortAltSorted : faSortAlt}
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th
                className={this.state.sortedBy === "author" ? "author sorted-by" : "author"}
                onClick={() => {
                  this.props.sortList("author.fullname");
                  let reverse = false;
                  if (this.state.sortedBy === "author" && this.state.reverse === false) reverse = true;
                  this.setState({ sortedBy: "author", reverse: reverse });
                }}
              >
                {/* {this.language.table_head.author} */}
                <GlobalToolTipController
                  offset={{ x: 15, y: 30 }}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Author</div>}
                >
                  Author{" "}
                  <FontAwesomeIcon
                    icon={this.state.sortedBy === "author" ? faSortAltSorted : faSortAlt}
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              {/* <th
                className={
                  this.state.sortedBy === "region"
                    ? "file-size sorted-by"
                    : "file-size"
                }
                onClick={() => {
                  this.props.sortList("region");
                  let reverse = false;
                  if (
                    this.state.sortedBy === "region" &&
                    this.state.reverse === false
                  )
                    reverse = true;
                  this.setState({ sortedBy: "region", reverse: reverse });
                }}
              > */}
              {/* {this.language.table_head.region} */}
              {/* <GlobalToolTipController
                  offset={{ x: 15, y: 30 }}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Region</div>}
                >
                  Region{" "}
                  <FontAwesomeIcon
                    icon={
                      this.state.sortedBy === "region"
                        ? faSortAltSorted
                        : faSortAlt
                    }
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController> */}
              {/* </th> */}
              <th
                className={this.state.sortedBy === "location" ? "location sorted-by" : "location"}
                onClick={() => {
                  this.props.sortList("location");
                  let reverse = false;
                  if (this.state.sortedBy === "location" && this.state.reverse === false) reverse = true;
                  this.setState({ sortedBy: "location", reverse: reverse });
                }}
              >
                {/* {this.language.table_head.location} */}
                <GlobalToolTipController
                  offset={{ x: 15, y: 30 }}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Location</div>}
                >
                  Locations{" "}
                  <FontAwesomeIcon
                    icon={this.state.sortedBy === "location" ? faSortAltSorted : faSortAlt}
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th
                className={this.state.sortedBy === "approvalStatus" ? "approvalStatus sorted-by" : "approvalStatus"}
                onClick={() => {
                  this.props.sortList("current_status");
                  let reverse = false;
                  if (this.state.sortedBy === "approvalStatus" && this.state.reverse === false) reverse = true;
                  this.setState({
                    sortedBy: "approvalStatus",
                    reverse: reverse,
                  });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 15, y: 30 }}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Approval Status</div>}
                >
                  Approval
                  <FontAwesomeIcon
                    icon={this.state.sortedBy === "approvalStatus" ? faSortAltSorted : faSortAlt}
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th
                className={this.state.sortedBy === "date" ? "date sorted-by" : "date"}
                onClick={() => {
                  this.props.sortList("date");
                  let reverse = false;
                  if (this.state.sortedBy === "date" && this.state.reverse === false) reverse = true;
                  this.setState({ sortedBy: "date", reverse: reverse });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 15, y: 30 }}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Date</div>}
                >
                  Date
                  <FontAwesomeIcon
                    icon={this.state.sortedBy === "date" ? faSortAltSorted : faSortAlt}
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th className="check-select">
                <GlobalToolTipController
                  offset={{ x: 10, y: 30 }}
                  title=""
                  delay={600}
                  toolTipElements={<div>Select all</div>}
                >
                  <VfiCheckbox
                    onChange={(e) => {
                      this.props.selectAll(e.target.checked);
                    }}
                  />
                </GlobalToolTipController>
              </th>
            </tr>
          </thead>
          <tbody>
            {returnList.map((element, i) => {
              if (this.props.showAmount > i)
                return (
                  <IsArticleContent
                    key={element.id}
                    element={element}
                    selectById={this.props.selectById}
                    selectAll={this.props.selectAll}
                    heartClicked={this.props.heartClicked}
                    expandSettings={this.expandSettings}
                    index={i}
                    editContent={this.props.editContent}
                    approvalStatusChange={this.props.approvalStatusChange}
                    approvalRequired={this.props.approvalRequired}
                    language={this.props.language}
                  />
                );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
export default IsArticleList;
