import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// var childPos2 = {x:0,y:0};
export default function OptionsInList(props) {
  const { element, i, close, setExtraListShown } = props;

  const defaultChoiceClickEffect = (dontCloseAfter) => {
    close(!!dontCloseAfter);
  };

  let result = "";
  if (element === "") return result;

  switch (element.is.toLowerCase()) {
    case "choice":
      {
        const icon = element.icon;
        const text = element.text;
        const onClick = element.onClick;
        const style = element.style;
        const rightIcon = element.rightIcon;
        const rightIconClick = element.rightIconClick;
        const rightIconStyle = element.rightIconStyle;

        result = (
          <div key={i} className="option-in-list" style={style}>
            <div
              className="clickable-area"
              onClick={() => {
                defaultChoiceClickEffect(element.dontCloseAfter);
                if (onClick !== undefined) {
                  onClick(element);
                }
              }}
            >
              {icon !== undefined ? (
                <div className="icon">
                  <FontAwesomeIcon icon={icon} />
                </div>
              ) : (
                ""
              )}
              {text !== undefined ? <div className="text">{text}</div> : ""}
              {rightIcon && <div className="right-icon-roomer" />}
            </div>
            {rightIcon && (
              <div className="right-icon" style={rightIconStyle} onClick={() => rightIconClick(element)}>
                <FontAwesomeIcon icon={rightIcon} />
              </div>
            )}
          </div>
        );

        if (element.element) result = element.element;

        //   amountAdded++;

        if (element.appear !== undefined)
          if (element.appear() === false) {
            result = "";
            //   amountAdded--;
          }
      }
      break;
    case "choice_list":
      {
        const icon = element.icon;
        const text = element.text;
        const onClick = element.onClick;
        const list = element.list;
        const style = element.style;

        result = (
          <div
            key={i}
            className="option-in-list"
            onMouseEnter={(e) => {
              // defaultChoiceClickEffect(element.dontCloseAfter);
              if (list !== undefined) {
                const newE = {
                  target: {
                    scrollHeight: e.target.scrollHeight,
                    scrollWidth: e.target.scrollWidth,
                  },
                };
                setExtraListShown(list, newE);
              }
            }}
            onMouseLeave={() => {}}
            onClick={(e) => {
              onClick(e);
            }}
            style={style}
          >
            <div className="clickable-area">
              {icon !== undefined ? (
                <div className="icon">
                  <FontAwesomeIcon icon={icon} />
                </div>
              ) : (
                ""
              )}
              {text !== undefined ? <div className="text">{text}</div> : ""}
              <FontAwesomeIcon className="point-right" icon={faChevronRight} />
            </div>
          </div>
        );

        if (element.element) result = element.element;

        //   amountAdded++;
        if (element.appear !== undefined)
          if (element.appear() === false) {
            result = "";
            //   amountAdded--;
          }
      }
      break;
    case "title":
      {
        const style = element.style;
        result = (
          <div className="title" key={i} style={style}>
            {element.text}
          </div>
        );
        // amountAdded++;
      }
      break;
    case "custom":
      result = element.element;
      // amountAdded++;
      break;
    case "break":
    case "hr":
    case "br":
      result = <hr key={i} />;
      // amountAdded++;
      break;
    case "blank":
    case "empty":
      //result = "";
      break;
    default:
      result = "Should not be shown...";
      // amountAdded++;
      break;
  }

  return result;
}
