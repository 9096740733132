import { create } from "zustand";
import { setType } from "../ZustandStores/zustandTypes";
import { ComponentType } from "react";
import { PermCheckProps, permCheckFunc } from "./PermCheck";
import { useUserStore } from "../ZustandStores/userStore";

interface PermCheckState {
  permCheckFunc: (props: PermCheckProps) => boolean;
}

const permCheckStore = (set: setType<PermCheckState>) => ({
  permCheckFunc: (props: PermCheckProps) => {
    const userStore = useUserStore.getState();
    const allProps: PermCheckProps = {
      permissions: userStore.permissionsCheck,
      roles: userStore.roles,
      ...props,
    };
    return permCheckFunc(allProps);
  },
});

export const usePermCheckStore = create<PermCheckState>(permCheckStore);

export const withSitesStore = (BaseComponent: ComponentType<any>) => (props: object) => {
  const store = usePermCheckStore();
  return <BaseComponent {...props} sitesStore={store} />;
};
