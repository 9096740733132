import React, { Component } from "react";
import VfiInputText from "../VfiInputText/VfiInputText";
import Switch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { faTimes, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faPlusSquare } from "@fortawesome/pro-solid-svg-icons";
import Select from "react-select";
import language from "./language.json";
import { cloneDeep } from "lodash";
import "./OpeningHours.scss";
import VfiCustomSelect from "../VfiCustomSelect";
import VerifyMethods from "../VerifyMethods";

function moreZero(val) {
  return val < 10 ? "0" + val : val.toString();
}

class OpeningHours extends Component {
  constructor(props) {
    super(props);

    let defaultValue = cloneDeep(this.props.defaultValue);

    let maxKey = Math.max(...defaultValue.map((e) => e.key ?? 0));

    defaultValue.forEach((e, i) => {
      if (e.key === undefined) {
        defaultValue[i].key = maxKey + 1;
        maxKey++;
      }
    });

    this.state = {
      openingHours: defaultValue,
    };

    this.addOpeningHours = this.addOpeningHours.bind(this);

    this.hours = [];
    for (let i = 0; i < 24; i++) {
      this.hours.push({ label: moreZero(i), value: moreZero(i) });
    }

    this.minutes = [];
    for (let i = 0; i < 60; i += 5) {
      this.minutes.push({ label: moreZero(i).toString(), value: moreZero(i) });
    }

    this.language = language[this.props.language];
  }

  addOpeningHours(e) {
    const oh = Object.assign([], this.state.openingHours);
    var highestKey = 0;
    oh.forEach((element, i) => {
      if (element.key > highestKey) highestKey = element.key;
    });
    oh.push({
      key: highestKey + 1,
      day: "",
      from: { hour: "", minutes: "" },
      to: { hour: "", minutes: "" },
      closed: false,
    });

    this.setState({ openingHours: oh });
    this.onChange(oh);
  }

  onChange(e) {
    if (this.props.onChange !== undefined) {
      this.props.onChange(e);
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({ openingHours: this.props.defaultValue });
    }
  };

  zeroes = { label: "00", value: "00" };

  render() {
    return (
      <div className="opening-hours">
        <div className="opening-hours-wrap">
          {this.state.openingHours.map((element, i) => {
            return (
              <div className="opening-hour-wrap" key={element.key}>
                <div
                  className="delete"
                  onClick={() => {
                    if (!this.props.disabled) {
                      const openingHoursD = JSON.parse(JSON.stringify(this.state.openingHours));
                      openingHoursD.splice(i, 1);
                      this.setState({ openingHours: openingHoursD });
                      this.onChange(openingHoursD);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                <div className="day">
                  <VfiInputText
                    disabled={this.props.disabled}
                    name="day"
                    value={element.day}
                    onChange={(e) => {
                      const openingHoursD = JSON.parse(JSON.stringify(this.state.openingHours));
                      openingHoursD[i].day = e.target.value;
                      this.setState({ openingHours: openingHoursD });
                      this.onChange(openingHoursD);
                    }}
                    placeholder={this.language.example}
                    errorCheckSequence={this.props.showErrors ? [VerifyMethods.notEmpty] : []}
                  />
                </div>
                <div className="time-from-to">
                  <div className="from">
                    <span className="text">{this.language.from}</span>
                    <VfiCustomSelect
                      isDisabled={this.props.disabled}
                      placeholder={this.language.hour}
                      options={this.hours}
                      value={element.from.hour}
                      onChange={(e) => {
                        const openingHoursD = JSON.parse(JSON.stringify(this.state.openingHours));
                        openingHoursD[i].from.hour = e;
                        if (!openingHoursD[i].from.minutes.value) openingHoursD[i].from.minutes = this.zeroes;
                        this.setState({ openingHours: openingHoursD });
                        this.onChange(openingHoursD);
                      }}
                      error={this.props.showErrors && !element.from.hour.value}
                    />
                    <VfiCustomSelect
                      isDisabled={this.props.disabled}
                      placeholder={this.language.minute}
                      options={this.minutes}
                      value={element.from.minutes}
                      onChange={(e) => {
                        const openingHoursD = JSON.parse(JSON.stringify(this.state.openingHours));
                        openingHoursD[i].from.minutes = e;
                        if (!openingHoursD[i].from.hour.value) openingHoursD[i].from.hour = this.zeroes;
                        this.setState({ openingHours: openingHoursD });
                        this.onChange(openingHoursD);
                      }}
                      error={this.props.showErrors && !element.from.minutes.value}
                    />
                  </div>
                  <div className="to">
                    <span className="text">{this.language.to}</span>
                    <VfiCustomSelect
                      isDisabled={this.props.disabled}
                      placeholder={this.language.hour}
                      options={this.hours}
                      value={element.to.hour}
                      onChange={(e) => {
                        const openingHoursD = JSON.parse(JSON.stringify(this.state.openingHours));
                        openingHoursD[i].to.hour = e;
                        if (!openingHoursD[i].to.minutes.value) openingHoursD[i].to.minutes = this.zeroes;
                        this.setState({ openingHours: openingHoursD });
                        this.onChange(openingHoursD);
                      }}
                      error={this.props.showErrors && !element.to.hour.value}
                    />
                    <VfiCustomSelect
                      isDisabled={this.props.disabled}
                      placeholder={this.language.minute}
                      options={this.minutes}
                      value={element.to.minutes}
                      onChange={(e) => {
                        const openingHoursD = JSON.parse(JSON.stringify(this.state.openingHours));
                        openingHoursD[i].to.minutes = e;
                        if (!openingHoursD[i].to.hour.value) openingHoursD[i].to.hour = this.zeroes;
                        this.setState({ openingHours: openingHoursD });
                        this.onChange(openingHoursD);
                      }}
                      error={this.props.showErrors && !element.to.minutes.value}
                    />
                  </div>
                </div>
                <div className="closed">
                  <div>{this.language.closed}</div>
                  <Switch
                    disabled={this.props.disabled}
                    checked={this.state.openingHours[i].closed}
                    onChange={() => {
                      const openingHours = JSON.parse(JSON.stringify(this.state.openingHours));
                      openingHours[i].closed = !openingHours[i].closed;
                      this.setState({ openingHours });
                      this.onChange(openingHours);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="add-icon" onClick={!this.props.disabled ? this.addOpeningHours : ""}>
          <span>{this.language.add}</span>
          <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
    );
  }
}

export default OpeningHours;
