import React from "react";
import "./VfiCheckbox.scss";

function VfiCheckbox({
  checked,
  defaultChecked,
  onChange,
  onClick,
  name,
  disabled,
  className,
  style,
  isRadio = false,
}) {
  return (
    <label className={className ? className : ""} style={style ? { ...style } : {}}>
      <input
        type="checkbox"
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        onClick={onClick}
        name={name}
        disabled={disabled}
      />
      {isRadio ? (
        <div className="vfi-radio-2">
          <div className="dot-inside" />
        </div>
      ) : (
        <div className="vfi-checkbox-2">
          <span className="left-shorter" />
          <span className="right-longer" />
        </div>
      )}
    </label>
  );
}
export default VfiCheckbox;
