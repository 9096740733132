import { routerStore, useRouterStore } from "src/Components/Router/routerStore";
import styles from "./SubmitEvent.module.scss";
import { useEffect, useState } from "react";
import validateForm from "../../forms/utils/form-validation";
import WhatsonCreateNew from "src/Components/Content/WhatsonDisplay/WhatsonCreateNew";
import AboutYou from "../../forms/about-you/AboutYou";

export interface IInformation {
  name: string;
  email: string;
  terms: boolean;
}

export default function SubmitEvent() {
  const { route, navByLink, get_parameters } = useRouterStore();

  const [information, setInformation] = useState<IInformation>({
    name: "",
    email: "",
    terms: false,
  });

  const [forward, setForward] = useState<boolean>(false);
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {!forward && <AboutYou information={information} setInformation={setInformation} setForward={setForward} />}
        {/* {route[2]?.nav} */}
        {forward && route[2]?.nav === "create" && (
          <>
            <h1 className={styles.title}>Submit event</h1>
            <div className="content-forWhatson">
              <div className="whatson-content">
                <WhatsonCreateNew
                  language={"english"}
                  newWhat={"event"}
                  submitChange={"false"}
                  updateData={undefined}
                  close={() => {
                    if (get_parameters?.returnUrl) {
                      window.location.href = get_parameters?.returnUrl;
                    }
                    // setForward(false);
                    // navByLink("submit/event");
                  }}
                  goToList={() => {}}
                  returnToSubmit={() => {
                    if (get_parameters?.returnUrl) {
                      window.location.href = get_parameters?.returnUrl;
                    }
                  }}
                  isFront={true}
                  // setSiteRef={() => console.log("Set site ref")}
                  setSiteRef={() => null}
                  userFront={null}
                  register={true}
                  nonUser={information}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
