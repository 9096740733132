import { useEffect, useState } from "react";
import SubmitChange from "src/Components/Login/submitChange";
import { useRouterStore } from "src/Components/Router/routerStore";
import { getContent } from "./utils";
import WhatsonCreateNew from "src/Components/Content/WhatsonDisplay/WhatsonCreateNew";
import axios from "axios";
import env from "../../../../../../environment.json";

export default function ChangeEvent() {
  const { route, get_parameters } = useRouterStore();

  const [editContent, setEditContent] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState("");

  const back_url = "#/";
  const nav = "event";
  const token = get_parameters?.edit_token;

  useEffect(() => {
    if (get_parameters?.id) {
      getContent(route[1]?.nav ?? "", Number(get_parameters?.id), (content: any) => {
        setEditContent(content);
      });
    }
  }, [get_parameters?.id]);

  useEffect(() => {
    setErrorMessage("");
    switch (nav) {
      case "event":
        axios
          .get(
            `${env.protocol}${env.env}/api/public/whatson/events/GetEvent?edit-token=${token ? token : "not-a-token"}`
          )
          .then((response) => {
            if (response?.data?.data?.length === 0) {
              setErrorMessage("Invalid data");
              return;
            }
            setEditContent(response.data);
          })
          .catch((error) => {
            console.log(error);

            // setErrorMessage(error.message);
          });
        break;
      default:
        setErrorMessage(`The following nav type '${nav}' is not considered valid.`);
    }
  }, [token]);

  if (errorMessage !== "") return <div className={"error-message"}>The error occured: {errorMessage}</div>;

  // This is just copied from the old code, but should be reworked if other flows should have this feature.
  if (editContent !== undefined) {
    return (
      <div className="content-forWhatson">
        <div className="whatson-content">
          <WhatsonCreateNew
            language={"english"}
            newWhat={route[1]?.nav ?? ""}
            updateData={editContent}
            close={() =>
              window.open(
                // `${domainBack}/${nav.toLowerCase()}s/${urlToGoBack ? (nav.toLowerCase()+"/"+urlToGoBack) : ""}`,
                decodeURIComponent(back_url),
                "_self"
              )
            }
            isFront={true}
            submitChange={"true"}
            // setSiteRef={() => console.log("Set site ref")}
            setSiteRef={() => null}
            creatingANew={false}
            editToken={token}
          />
        </div>
      </div>
    );
  } else {
    return <div>loading</div>;
  }
}
