import { faCamera, faSignsPost, faUtensils } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import "../../shared/SharedListDisplay.scss";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import { faSlidersH } from "@fortawesome/pro-regular-svg-icons";
import { useCallback } from "react";
import IsArticlesDisplay from "./IsArticlesDisplay";
import EditPage from "../../Pages/EditPage/EditPage";
import axios from "axios";
import env from "../../../../environment.json";

function InfoscreenArticles({
  hideList = false,
  articleInitialType = "See & do",
  openOverlay = () => void 0,
  setConfirmBeforeNavigate = () => void 0,
  setSiteRef = () => void 0,
  newData,
  setArticleLocation = (location) => {},
}) {
  const [articlesType, setArticlesType] = useState(articleInitialType);
  const [currentLocation, setCurrentLocation] = useState("menu");
  const [editData, setEditData] = useState(undefined);

  const createIsArticle = () => {
    axios
      .post(env.protocol + env.env + "/api/secured/infoscreen/infoscreenArticles/addArticleIs", {
        type: articlesType,
        userId: sessionStorage.getItem("vfiUser"),
        actualTypeName: "Infoscreens",
      })
      .then((response) => {
        setSiteRef(response.data.page.site_id);
        setCurrentLocation("edit");
        setEditData(response.data.page);
      })
      .catch((error) => console.error(error));
  };

  const editIsArticle = (e) => {
    axios
      .get(env.protocol + env.env + "/api/public/pages/GetPage.php?id=" + e)
      .then((response) => {
        setSiteRef(response.data.page.site_id);
        setCurrentLocation("edit");
        setEditData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let content = "InfoscreenArticles.jsx missing key";

  useEffect(() => {
    setArticleLocation(currentLocation);
  }, [currentLocation]);

  switch (currentLocation) {
    case "menu":
      content = (
        <>
          <div className="list-parent gray" style={{ display: hideList ? "none" : "block" }}>
            <div className="heading-padding sub">TYPE</div>
            {/* {whatsonList.map((whatsonItem) => {
                        return (
                            <div
                                key={`whatsonItem-${whatsonItem.id}`}
                                className={`list-option ${whatsonItem.name.toLowerCase() === 'hiking' ? 'disabled' : ''} ${selectedMenuID === whatsonItem.id ? 'selected' : ''}`}
                                onClick={() => {
                                    if (whatsonItem.name.toLowerCase() !== 'hiking') {
                                        setSelectedMenuID(whatsonItem.id);
                                    }
                                }}
                            >
                                {whatsonItem?.name}
                            </div>
                        )
                    })} */}
            <div
              className={`list-option ${articlesType === "See & do" ? "selected" : ""}`}
              onClick={() => {
                setArticlesType("See & do");
              }}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faCamera} />
              </span>
              See & do
            </div>
            <div
              className={`list-option ${articlesType === "Eat & drink" ? "selected" : ""}`}
              onClick={() => {
                setArticlesType("Eat & drink");
              }}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faUtensils} />
              </span>
              Eat & drink
            </div>
            <div
              className={`list-option ${articlesType === "Transport" ? "selected" : ""}`}
              onClick={() => {
                setArticlesType("Transport");
              }}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faSignsPost} />
              </span>
              Transport
            </div>
          </div>
          <div className="is-articles-content">
            <IsArticlesDisplay
              articlesType={articlesType}
              createIsArticle={createIsArticle}
              editIsArticle={editIsArticle}
            />
          </div>
        </>
      );
      break;
    case "edit":
      // Only copied directly from pages..
      content = editData && (
        <EditPage
          // key={editData.page.id + " " + refreshTime}
          data={editData}
          newData={newData}
          setSiteRef={setSiteRef}
          // editPage={editPage}
          // screenSize={editData.screenSize}
          openOverlay={openOverlay}
          // setIsLoading={(e) => setIsLoading(e)}
          close={() => {
            setCurrentLocation("menu");
            setEditData(undefined);
            // setIsLoading(false);
          }}
          // deletePage={deletePage}
          setConfirmBeforeNavigate={setConfirmBeforeNavigate}
        />
      );
      break;
    default:
      break;
  }

  return <div className="infoscreen-articles">{content}</div>;
}

InfoscreenArticles.propTypes = {
  openOverlay: PropTypes.func,
  setConfirmBeforeNavigate: PropTypes.func,
  setSiteRef: PropTypes.func,
  newData: PropTypes.object,
};

export default InfoscreenArticles;
