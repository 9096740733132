import { useEffect, useState } from "react";
import styles from "./Registration.module.scss";
import VerifyEmail from "./components/verify-email/VerifyEmail";
import { useRouterStore } from "src/Components/Router/routerStore";
import VfiLogo from "../route-components/VfiLogo/VfiLogo";
import VfiInput from "src/assets/VfiInput/VfiInput";
import VerifyMethods from "src/assets/VerifyMethods";
import { IFormInput, IRegistrationForm } from "./types";
import VfiButton from "src/assets/VfiButton/VfiButton";
import { formatForm, validateForm } from "./utils";
import VfiCustomSelect from "src/assets/VfiCustomSelect";
import countryCodes from "../../../../assets/countryCodes.json";
import TermsOfService from "../Submit/forms/terms-of-service/TermsOfService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader, faPerson, faSpinner, faUser } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import axios from "axios";

import env from "../../../../environment.json";
import { error } from "console";
import { overlayStore } from "src/Components/OverlayElements/OverlayStore";

interface Props {}

const INITIAL_FORM_INPUT: (value: any) => IFormInput<any> = (value) => {
  return {
    value: value,
    error: "",
  };
};

const INITIAL_FORM: IRegistrationForm = {
  first_name: INITIAL_FORM_INPUT(""),
  last_name: INITIAL_FORM_INPUT(""),
  email: INITIAL_FORM_INPUT(""),
  confirm_email: INITIAL_FORM_INPUT(""),
  password: INITIAL_FORM_INPUT(""),
  phone_number: INITIAL_FORM_INPUT(""),
  country_code: INITIAL_FORM_INPUT("298"),
  work: INITIAL_FORM_INPUT(""),
  website: INITIAL_FORM_INPUT(""),
  social_media: INITIAL_FORM_INPUT(""),
  terms_and_conditions: INITIAL_FORM_INPUT(false),
};

const COUNTRY_CODE_MAP = countryCodes.map((e: any) => {
  return {
    label: `+${e.dial_code} ${e.name}`,
    value: e.dial_code,
  };
});

export default function Registration({}: Props) {
  const { route, navByLink, get_parameters } = useRouterStore();

  const { addOverlay } = overlayStore();

  const [form, setForm] = useState<IRegistrationForm>(INITIAL_FORM);

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className={styles.container}>
      <VfiLogo className={styles.logo} />

      <div className={styles.seperator}>or</div>

      {route[1]?.nav === "verify_email" ? (
        <VerifyEmail />
      ) : (
        <>
          <div className={styles.form}>
            <h3 className={styles.title}>Register with email</h3>

            <div className={`${styles.section} ${styles.email}`}>
              {/* Email */}
              {["email", "confirm_email"].map((type: string) => {
                return (
                  <div className={`${styles.wrapper} ${styles.email_wrapper}`}>
                    <p>What is your email?</p>
                    <VfiInput
                      className={styles.input}
                      type={"email"}
                      placeholder={type === "email" ? "Email" : "Enter email again"}
                      value={form?.[type]?.value}
                      error={form?.[type]?.error}
                      onChange={(e: any) => {
                        // TODO: Validate email
                        // const error: string = type === 'email'? VerifyMethods.validEmail(e.target.value) :
                        //     form?.['email']?.value !== e.target.value? 'Emails do not match' : '';
                        const error: string = VerifyMethods.validEmail(e.target.value);

                        setForm({
                          ...form,
                          [type]: {
                            value: e.target.value,
                            // error: error,
                            error: "",
                          },
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>

            {/* Password */}
            <div className={`${styles.section} ${styles.password}`}>
              <div className={`${styles.wrapper} ${styles.password_wrapper}`}>
                <p>Create a password?</p>
                <VfiInput
                  className={styles.input}
                  type={"password"}
                  placeholder={"Create a password"}
                  value={form.password.value}
                  error={form.password.error}
                  onChange={(e) => {
                    const error = VerifyMethods.validPassword(e.target.value);

                    setForm({
                      ...form,
                      password: {
                        value: e.target.value,
                        // error: error,
                        error: "",
                      },
                    });
                  }}
                />
              </div>
            </div>

            {/* Name */}
            <div className={`${styles.section} ${styles.name}`}>
              <div className={styles.wrapper}>
                <p>What are we calling you?</p>
                {["first_name", "last_name"].map((type: string) => {
                  return (
                    <VfiInput
                      className={styles.input}
                      placeholder={type === "first_name" ? "First name" : "Last name"}
                      value={form?.[type]?.value}
                      error={form?.[type]?.error}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [type]: {
                            value: e.target.value,
                            error: "",
                          },
                        });
                      }}
                    />
                  );
                })}
              </div>
            </div>

            {/* Phone */}
            <div className={`${styles.section} ${styles.phone}`}>
              <div className={`${styles.wrapper} ${styles.phone}`}>
                <p>What is your phone number?</p>
                <div className={styles.phone_inputs}>
                  <div className={styles.code}>
                    <VfiCustomSelect
                      className={styles.country_code}
                      name={"CountryCode"}
                      placeholder={"Country Code"}
                      options={COUNTRY_CODE_MAP}
                      defaultValue={{
                        value: 298,
                        label: `+298`,
                      }}
                      value={{
                        value: form?.country_code?.value,
                        label: `+${form?.country_code?.value}`,
                      }}
                      onChange={(e: any) => {
                        setForm({
                          ...form,
                          country_code: {
                            value: e.value,
                            error: "",
                          },
                        });
                      }}
                      classNames={{
                        menu: (state: any) => styles.country_code_menu,
                      }}
                      styles={{
                        control: (base: any, state: any) => ({
                          ...base,
                          width: "90px",
                          height: "100%",
                          border: "none",
                        }),
                        valueContainer: (base: any, state: any) => ({
                          ...base,
                          width: "90px",
                          height: "100%",
                          transform: "translateX(-8px)",
                        }),
                        container: (base: any) => ({
                          ...base,
                          width: "90px",
                          height: "100%",
                          paddingBottom: "6px",
                          border: "none",
                          boxShadow: "none",
                        }),
                      }}
                    />
                  </div>
                  <VfiInput
                    className={styles.input}
                    placeholder={"Phone number"}
                    type={"tel"}
                    value={form?.phone_number?.value}
                    error={form?.phone_number?.error}
                    onChange={(e) => {
                      if (isNaN(e.target.value)) return;
                      setForm({
                        ...form,
                        phone_number: {
                          value: e.target.value,
                          error: "",
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Optionals */}
            <div className={`${styles.section} ${styles.optional}`}>
              {Object.entries({
                work: {
                  title: "Where do you work?",
                  placeholder: "Company name",
                },
                website: {
                  title: "Do you have a website?",
                  placeholder: "Enter website link",
                },
                social_media: {
                  title: "Do you have a social media account?",
                  placeholder: "Enter social media link",
                },
              }).map(([key, value]: any) => {
                return (
                  <div className={`${styles.wrapper} ${styles?.[key]}`}>
                    <p>{value.title} (Optional)</p>
                    <VfiInput
                      className={styles.input}
                      placeholder={value.placeholder}
                      value={form?.[key]?.value}
                      onChange={(e: any) => {
                        setForm({
                          ...form,
                          [key]: {
                            value: e.target.value,
                            error: "",
                          },
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>

            {/* Terms and Conditions */}
            <div className={`${styles.section} ${styles.terms_and_conditions}`}>
              <div className={`${styles.wrapper} ${styles.terms_and_conditions}`}>
                <TermsOfService
                  value={form?.terms_and_conditions?.value}
                  error={{ valid: form?.terms_and_conditions?.error === "" }}
                  onChange={(e: any) => {
                    setForm({
                      ...form,
                      terms_and_conditions: {
                        value: e.target.checked,
                        error: "",
                      },
                    });
                  }}
                />
              </div>
            </div>

            {/* Register */}
            <VfiButton
              className={`${styles.register} ${loading ? styles.loading : ""}`}
              onClick={() => {
                if (loading) return;

                const { valid, form: new_form } = validateForm(form);

                setForm({ ...new_form });

                if (valid) {
                  setLoading(true);

                  axios
                    .post(`${env.protocol}${env.env}/api/public/registerUser`, formatForm(form))
                    .then((response) => {
                      // Send mail, if successful, redirect user

                      if (response?.data?.data?.pending) {
                        navByLink("register/verify_email", {
                          redirect: get_parameters?.redirect ?? "",
                          email: form.email.value,
                        });
                      }
                    })
                    .catch((error) => {
                      addOverlay("side_popout_self_close", (close) => {
                        return (
                          <div className={styles.popout_error}>
                            {error.response?.data?.message ?? "An error occured"}
                          </div>
                        );
                      });
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                } else {
                  addOverlay("side_popout_self_close", (close) => {
                    return <div className={styles.popout_error}>Required fields missing!</div>;
                  });
                }
              }}
            >
              {!loading ? (
                "Register"
              ) : (
                <div className={styles.loading_text}>
                  Registering <FontAwesomeIcon spin icon={faLoader as IconProp} />
                </div>
              )}
            </VfiButton>
          </div>
          <button
            className={styles.login_button}
            onClick={() => {
              navByLink("login");
            }}
          >
            <p>Already have a user, login here</p>
            <FontAwesomeIcon icon={faUser as IconProp} />
          </button>
        </>
      )}
    </div>
  );
}

// Currently not implemented
export const RegistrationView = {
  component: Registration,
  props: (additional_props: any) => ({
    ...additional_props,
  }),
};
