import { get } from "lodash";
import { iDataPermissions } from "../PermCheck/PermCheck";
import { usePermCheckStore } from "../PermCheck/PermCheckStore";
import { Route, navigationElements } from "./navigationElements";

export function initRouteArray(permissionsCheck?: any): {
  route: Route[];
  get: { [key: string]: any };
} {
  if (permissionsCheck === undefined) {
    permissionsCheck = () => {
      return false;
    };
  }

  // Get partial links from hash
  let big_links = window.location.hash.split("?");
  let partialLinks = big_links[0].split("/");
  const get_params = big_links.length > 1 ? big_links[big_links.length - 1] : null;

  // Remove redundant element from array
  partialLinks.shift();

  // Initialize get paramters
  let get_parameters = {};

  // Check if there are any links
  if (get_params) {
    get_parameters = Object.fromEntries(
      get_params?.split("&").map((param) => {
        return param.split("=");
      })
    );
  }

  // Remove question marks from partial links
  partialLinks = partialLinks.map((link) => {
    return link.split("?")[0];
  });

  let obj: Route = {};

  let routeArr: Route[] = [];

  //
  if (partialLinks.length === 0) {
    routeArr.push({
      nav: navigationElements.home.nav,
      sidebar: navigationElements.home.sidebar,
      displayName: navigationElements.home.displayName,
    });

    return {
      route: routeArr,
      get: get_parameters,
    };
  }

  let el: Route = navigationElements[partialLinks[0].split("?")[0]];

  let ignore_auth = el?.noAuth;

  if (
    el &&
    (permissionsCheck({
      type: el?.checkType,
      id: el?.checkId,
      minimumRole: el?.checkMinimumRole,
    }) ||
      ignore_auth)
  ) {
    obj = {
      nav: el.nav,
      sidebar: el.sidebar,
      displayName: el.displayName,
    };
  } else {
    obj = {
      nav: navigationElements.home.nav,
      sidebar: navigationElements.home.sidebar,
      displayName: navigationElements.home.displayName,
    };
  }

  if (el?.sections) obj["sections"] = el.sections;
  routeArr.push(obj);

  for (let i = 1; i < partialLinks.length; i++) {
    const refSection = obj.sections;
    if (refSection !== undefined) {
      const refObj = refSection[partialLinks[i]];
      obj =
        refObj &&
        (permissionsCheck({
          type: refObj.checkType,
          id: refObj.checkId,
          minimumRole: refObj.checkMinimumRole,
        }) ||
          ignore_auth)
          ? refObj
          : refSection.default;

      let vals: string[] = partialLinks[i].split("?")[0].split("-");
      if (vals[0] === "edit") {
        obj.nav = partialLinks[i];
        obj.displayName = partialLinks[i];
      }
      if (vals[0] === "id") {
        obj.nav = vals[1];
        obj.displayName = "";
      }
    }

    routeArr.push(obj);
  }

  return {
    route: routeArr,
    get: get_parameters,
  };
}
