import { useEffect, useRef, useState } from "react";
import styles from "./UploadMedia.module.scss";
import VfiInputText from "src/assets/VfiInputText/VfiInputText";
import VfiTextarea from "src/assets/VfiTextarea/VfiTextarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faUpDownLeftRight, faImage, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEllipsis, faEllipsisV, faTrash } from "@fortawesome/pro-solid-svg-icons";
import MediaListOverlay from "src/Components/MediaCenter/MediaListOverlay";
import MediaCenter from "src/Components/MediaCenter";

import media_styles from "../../../../../MediaCenter/MediaCenter.module.scss";
import Dropzone from "react-dropzone";

interface IMediaLanguage {
  [language: string]: {
    placeholder: string;
  };
}

interface Props {
  default_elements?: any[];
  // TODO: Maybe just make auth a custom upload as this this doesn't strictly apply to authorized use cases.
  auth?: {
    status: boolean;
    call: (e: any) => void;
  };
  addMedia?: (media_list: any) => void;
  onElementsChange?: (elements: any[]) => void;
}

const languages: IMediaLanguage = {
  en: {
    placeholder: "English text",
  },
  fo: {
    placeholder: "Faroese text",
  },
};

export default function UploadMedia({
  default_elements = [],
  auth = { status: false, call: (e: (media_elements: any[]) => void) => {} }, // Maybe just make auth a custom upload as this this doesn't strictly apply to authorized use cases.
  addMedia = (media_list: any) => {},
  onElementsChange = (e: any) => {},
}: Props) {
  /**
   * Our media elements
   */
  const [media_elements, setMediaElements] = useState<any[]>(default_elements);

  /**
   * Current index is a name inspired by another component doing something similar
   * changing the name to something more descriptive would probably be a good idea.
   */
  const [current_index, setCurrentIndex] = useState<number | null>(null);

  /**
   * Dragged index is the index of the element we're dragging
   */
  const [dragged_index, setDraggedIndex] = useState<number | null>(null);

  /**
   * Upload hovered
   */
  const [upload_hovered, setUploadHovered] = useState<boolean>(false);

  /**
   * Options overlay
   */
  const [options_overlay, setOptionsOverlay] = useState<any | null>(null);

  /**
   * State for toggling media center
   */
  const [media_center, setMediaCenter] = useState<boolean>(false);

  /**
   *  -- Dome Element References --
   */
  const file_input_ref = useRef<any | null>(null);
  const options_ref = useRef<any>(null);

  /**
   * Sets an element to the first element in the elements list by its index
   */
  const setAsMain = (index: number) => {
    const new_elements = [...media_elements];
    const [selected_element] = new_elements.splice(index, 1);
    new_elements.unshift(selected_element);
    setMediaElements([...new_elements]);
    setOptionsOverlay(null);
  };

  /**
   * Delete media deletes and element from an array by its index
   */
  const deleteMedia = (index: number) => {
    setMediaElements([...media_elements].filter((_, idx) => idx !== index));
    setOptionsOverlay(null);
  };

  /**
   * Function for handling file inputs
   */
  const handleFileInput = (files: any) => {
    // Create an array to hold the read file data URLs
    let newMediaElements: any[] = [];

    // Function to process each file
    const processFile = (file: any, callback: any, index: number) => {
      if (file.type.match("image.*")) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          newMediaElements.push({
            file: file,
            url: e.target.result,
            text: {
              ...Object.fromEntries(
                Object.keys(languages).map((key) => {
                  return [key, ""];
                })
              ),
            },
          });
          callback(); // Invoke the callback after processing
        };
        reader.readAsDataURL(file);
      }
    };

    // Function to update state after all files are processed
    const updateState = () => {
      setMediaElements((prevElements) => [...prevElements, ...newMediaElements]);
    };

    // Process files and keep track of completion
    let count = 0;
    files.forEach((file: any, index: number) => {
      processFile(
        file,
        () => {
          count++;
          if (count === files.length) {
            updateState(); // Update state when all files are processed
          }
        },
        index
      );
    });
  };

  /**
   * General useEffect for initializing listeners
   */
  useEffect(() => {
    const handleClick = (e: any) => {
      e.stopPropagation();
      let container = options_ref?.current;
      if (container) {
        if (e.target.contains(container) && e.target !== container) {
          setOptionsOverlay(null);
        }
      }
    };
    const handleScroll = (e: any) => {
      setOptionsOverlay(null);
    };
    window.addEventListener("click", handleClick);
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("click", handleClick);
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  /**
   * For invoking the elements change callback when the elements list changes
   */
  useEffect(() => {
    onElementsChange(media_elements);
  }, [media_elements]);

  /**
   * Set default, hopefully no infinite callback !!!
   */
  // useEffect(() => { onElementsChange(default_elements) }, [default_elements]);

  /**
   * Render
   */
  return (
    <div
      className={styles.container}
      onMouseUp={(e) => {
        setDraggedIndex(null);
      }}
      onDrop={(e) => {
        e.preventDefault();
        setDraggedIndex(null);
      }}
    >
      {/* For some reason we need this dropzone, because something in the application prevent the drop listener from working in some cases :C */}
      <Dropzone onDrop={() => {}}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            {/* Media elements */}
            <div className={styles.media_elements}>
              {media_elements.map((media, index) => {
                return (
                  /* Single element */
                  <div
                    key={`media-element-${index}`}
                    className={`${styles.media} ${current_index === index ? styles.drag_over : ""}`}
                  >
                    {/* Image wrapper */}
                    <div
                      className={styles.image_wrapper}
                      onClick={() => {
                        setOptionsOverlay(null);
                      }}
                      onDragStart={(e) => {
                        setDraggedIndex(index);
                      }}
                      onDragOver={(e) => {
                        if (current_index !== index) {
                          e.preventDefault();
                          setCurrentIndex(index);
                        }
                      }}
                      onDragEnter={(e) => {
                        e.preventDefault();
                        setCurrentIndex(index);
                      }}
                      onDragLeave={(e) => {
                        setCurrentIndex(null);
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        if (current_index !== null && dragged_index !== null) {
                          /* Move elements */
                          const new_elements = [...media_elements];
                          const [draggedItem] = new_elements.splice(dragged_index, 1);
                          new_elements.splice(current_index, 0, draggedItem);

                          setMediaElements([...new_elements]);
                        }
                        setCurrentIndex(null);
                        setDraggedIndex(null);
                      }}
                    >
                      {/* Image */}
                      <img
                        onDrop={(e) => {
                          e.preventDefault();
                        }}
                        className={`${styles.image} ${dragged_index === index ? styles.dragging : ""} ${
                          index === current_index ? styles.to_drop : ""
                        }`}
                        src={media.url}
                        alt=""
                        draggable
                      />
                    </div>

                    {/* Text-fields */}
                    {Object.keys(languages).map((language) => {
                      return (
                        <VfiTextarea
                          className={styles.input}
                          height={85}
                          value={media.text[language]}
                          placeholder={languages[language].placeholder}
                          onChange={(e: any) => {
                            /* Update changed element */
                            setMediaElements(
                              [...media_elements].map((element) => {
                                if (media_elements.indexOf(element) !== index) return element;
                                return {
                                  ...element,
                                  text: {
                                    ...element.text,
                                    [language]: e,
                                  },
                                };
                              })
                            );
                          }}
                        />
                      );
                    })}

                    {/* Move image indicator (Overlay) */}
                    <div className={styles.move_overlay}>
                      <FontAwesomeIcon className={styles.move_icon} icon={faUpDownLeftRight as IconProp} />
                    </div>

                    {/* Details (Overlay) */}
                    <div className={styles.details}>
                      {index === 0 && <div className={styles.detail}>Featured Image</div>}
                      <div className={styles.detail}>Image {index + 1}</div>
                    </div>

                    {/* Options button (Overlay) */}
                    <button
                      className={styles.options}
                      onClick={(e: any) => {
                        let rect = e.target.getBoundingClientRect();

                        /* Toggle options overlay */
                        setOptionsOverlay(
                          options_overlay === null
                            ? {
                                position: {
                                  right: window.innerWidth - rect.right,
                                  top: rect.bottom + 10,
                                },
                                index: index,
                              }
                            : null
                        );
                      }}
                    >
                      <FontAwesomeIcon className={styles.ellipsis} icon={faEllipsisV as IconProp} />
                    </button>
                  </div>
                );
              })}

              {/* Empty image for uploading images */}
              <div
                className={`${styles.media} ${styles.empty}`}
                onDragOver={(e) => {
                  if (!upload_hovered) {
                    e.preventDefault();
                    setUploadHovered(true);
                  }
                }}
                onDragEnter={(e) => {
                  setUploadHovered(true);
                }}
                onDragLeave={(e) => {
                  setUploadHovered(false);
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  setUploadHovered(false);
                  setDraggedIndex(null);
                  if (dragged_index !== null) return; // If an element is dragged we wont upload

                  const files = e.dataTransfer.items
                    ? Array.from(e.dataTransfer.items)
                        .map((item) => (item.kind === "file" ? item.getAsFile() : null))
                        .filter((item) => item !== null)
                    : Array.from(e.dataTransfer.files);
                  handleFileInput(files);
                }}
              >
                {/* Upload button */}
                <button
                  className={`${styles.image} ${upload_hovered ? styles.hovered : ""}`}
                  onClick={() => {
                    if (auth.status) {
                      auth.call((new_elements: any[]) => {
                        setMediaElements([...media_elements, ...new_elements]);
                      });
                      // setMediaCenter(true);
                      return;
                    }
                    if (file_input_ref?.current) file_input_ref.current.click();
                  }}
                >
                  <div className={styles.upload_content}>
                    <FontAwesomeIcon className={styles.icon} icon={faCamera as IconProp} />
                    <p>Upload Image</p>
                  </div>
                </button>

                {/* File input */}
                <input
                  ref={file_input_ref}
                  type="file"
                  style={{ display: "none" }}
                  multiple
                  accept=".jpg,.jpeg,.png,.gif,.mp4"
                  onChange={(e: any) => {
                    e.preventDefault();
                    handleFileInput(Array.from(e.target.files));
                    e.target.value = "";
                  }}
                />
              </div>

              {/* Options overlay */}
              {options_overlay !== null && (
                <div
                  className={styles.options_overlay}
                  ref={options_ref}
                  style={{
                    right: options_overlay.position.right,
                    top: options_overlay.position.top,
                  }}
                >
                  {/* Options: Set to front */}
                  <button
                    className={`${styles.option} ${styles.set_main}`}
                    onClick={() => {
                      setAsMain(options_overlay.index);
                    }}
                  >
                    <FontAwesomeIcon className={styles.option_icon} icon={faImage as IconProp} />
                    <p>Set as main image</p>
                  </button>

                  {/* Options: Delete Image */}
                  <button
                    className={`${styles.option} ${styles.delete}`}
                    onClick={() => {
                      deleteMedia(options_overlay.index);
                    }}
                  >
                    <FontAwesomeIcon className={styles.option_icon} icon={faTrashCan as IconProp} />
                    <p>Delete image</p>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
}
