import { Component } from "react";
import GoogleMapSearchBox from "../../../../assets/GoogleMapSearchBox";
import Verify from "../../../../assets/VerifyMethods";
import { SimpleMap, SearchBox } from "../../../../assets/GoogleMaps";
import Select from "../../../../assets/VfiCustomSelect";
import zipCodes from "../../../../assets/zipCodes.json";
import countryCodes from "../../../../assets/countryCodes.json";
import env from "../../../../environment.json";
import axios from "axios";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import torshavnRegions from "../TorshavnRegions.json";

/**
 * Build the zip codes array for select
 *
 * Zip codes are retrieved from assets, but need to be formatted for use in select
 *
 * @returns 	{array} 										Array of zipcodes for select
 *
 * @author 					Pætur Mortensen
 */
function build_select_zip_codes() {
  const returnArr = [];

  for (const idx in zipCodes) {
    const zip = zipCodes[idx];

    returnArr.push({
      value: zip.code,
      label: zip.code + " " + zip.city,
    });
  }
  return returnArr;
}

/**
 * Build the phone country codes for select
 *
 * @returns 	{array} 												Array of phone country codes formatted for select
 *
 * @author 					Pætur Mortensen
 */
function build_select_country_codes() {
  // Build the country codes for select
  const cCodes = [];
  // Register used codes so we get unique array
  const usedCodes = [];
  for (const idx in countryCodes) {
    const code = countryCodes[idx].dial_code;

    if (!usedCodes.includes(code)) {
      cCodes.push({
        value: code,
        label: "+" + code,
      });

      usedCodes.push(code);
    }
  }

  // Sort the country codes numerically and return
  return cCodes.sort((a, b) => a.value - b.value);
}

class Location extends Component {
  /**
   * props:
   * @property 		{object} 		newTourOperator 					Tour operator information
   * @property 		{object} 		language 									Language object with strings
   * @property 		{object} 		state 										NewTourOperator component state object
   * @property		{function} 	setState 									NewTourOperator setState method
   * @property		{component} whatsonNavigation 				WhatsonNavigation component
   *
   * @author 					Pætur Mortensen
   */
  constructor(props) {
    super(props);

    this.state = {
      regions: [],
    };

    // Build the zipcodes for select
    this.zipCodes = build_select_zip_codes();

    // Build the phone country codes for select
    this.countryCodes = build_select_country_codes();

    // Set event handlers
    this.onMarkerChange = this.onMarkerChange.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
  }

  componentDidMount() {
    // Get regions from backend and set them for select

    // Set the regions
    axios
      .get(env.protocol + env.env + "/api/GetRegions")
      .then((response) => {
        const regions = [];
        response.data.forEach((element) => {
          regions.push({
            label: element.region_name,
            value: element.region_id,
          });
        });
        this.setState({ regions });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * Handle marker change
   *
   * When user places marker on map
   *
   * @param 	{object} 	e 								Marker change event object
   */
  onMarkerChange(e) {
    const event = e.mapMouseEvent;
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    this.regionAndZipPopulate(e.geocode);
    this.props.newTourOperator.address.name = e.geocode.results[0]
      ? e.geocode.results[0].formatted_address
      : this.newHiking.address.name;

    this.props.newTourOperator.address = {
      ...this.props.newTourOperator.address,
      showMarker: true,
      mapMarker: { lat: lat, lng: lng },
      // mapCenter: { lat: lat, lng: lng },
    };
    this.props.setState({});
  }

  /**
   * Handle address change
   *
   * @param 	{object} 	data 								Address data
   */
  onAddressChange(data) {
    const lat = data.geometry.location.lat(data.formatted_address);
    const lng = data.geometry.location.lng(data.formatted_address);
    if (data.address_components)
      this.regionAndZipPopulate({
        results: [{ address_components: data.address_components }],
      });

    this.props.newTourOperator.address = {
      ...this.props.newTourOperator.address,
      name: data.formatted_address,
      // mapCenter: { lat: lat, lng: lng },
      mapZoom: 15,
      showMarker: true,
      mapMarker: { lat: lat, lng: lng },
    };

    this.props.setState({
      address: data.formatted_address,
      // mapCenter: { lat: lat, lng: lng },
      mapZoom: 15,
      showMarker: true,
      mapMarker: { lat: lat, lng: lng },
      beenEdit: { ...this.props.state.beenEdit, address: true },
    });
  }

  /**
   * Populate the region and zip selects from google maps results
   *
   * Since the API data is far from complete, and does not conform perfectly with our regions,
   * we make a soft search, where we attempt to return a result on match.
   *
   * @param 	{object} 	data 									Region and zip data
   *
   * @author 					Pætur Mortensen
   */
  regionAndZipPopulate(data) {
    // Init region and zip as null (not found)
    let region = null;
    let zip = null;

    // Build a zipSearch array for searching zips by string
    const zipSearch = this.zipCodes.map((zipCode) => {
      const searchString = zipCode.label.replace(zipCode.value + " ", "").toLowerCase();
      return { searchString, value: zipCode.value };
    });

    // Build a regionSearch array for searching regions by string (replace some strings for google)
    const replacementMap = [
      { region: "norðoyggjar", replace: "northern isles" },
      { region: "suðuroy", replace: "suduroy" },
      { region: "eysturoy", replace: "eysturoyar" },
      { region: "streymoy", replace: "streymoyar" },
    ];
    const regionSearch = this.state.regions.map((region) => {
      let searchString = region.label.toLowerCase();
      // Replace string if found in replacement map
      const findObj = replacementMap.find((item) => item.region === searchString);
      if (findObj) {
        searchString = findObj.replace;
      }

      return { searchString, value: region.value };
    });

    /**
     * Check whether address component matches a region
     *
     * @param 		{object} 	comp 										Google maps API address component
     *
     * @returns 	{int|null} 												Region ID if found, else NULL
     *
     * @author 					Pætur Mortensen
     */
    function matches_region(comp) {
      // Get matching index, if any (check if short_name or long_name matches)
      const idx = regionSearch.findIndex((region) => {
        return (
          region.searchString === comp.long_name.toLowerCase() || region.searchString === comp.short_name.toLowerCase()
        );
      });

      // If there is a match...
      if (idx !== -1) {
        return regionSearch[idx].value;
      } else {
        return null;
      }
    }

    /**
     * Check whether an address component matches any zip
     *
     * @param 		{object} 	comp 											Google maps API address component
     *
     * @returns 	{int|null} 													Zip if found, else NULL
     *
     * @author 					Pætur Mortensen
     */
    function matches_zip(comp) {
      // Get matching index, if any (check if short_name or long_name matches)
      const idx = zipSearch.findIndex((zip) => {
        return zip.searchString === comp.long_name.toLowerCase() || zip.searchString === comp.short_name.toLowerCase();
      });

      // If there is a match..
      if (idx !== -1) {
        return zipSearch[idx].value;
      } else {
        return null;
      }
    }

    // Iterate over all address components and attempt to find zip and region matches

    // Set label to break out to from nested loop
    // For each google maps API result...
    addressBreak: for (const resIdx in data.results) {
      const result = data.results[resIdx];
      // For each address component....
      for (const addIdx in result.address_components) {
        const addressComponent = result.address_components[addIdx];

        // Check whether address component matches zip and set zip if it does
        const zipMatch = matches_zip(addressComponent);
        if (zipMatch !== null) {
          zip = zipMatch;
        }

        // Check whether address component matches region and set region if it does
        const regionMatch = matches_region(addressComponent);
        if (regionMatch !== null) {
          region = regionMatch;
        }

        // If region and zip have been found, break out of nested loop (to label)
        if (region !== null && zip !== null) {
          break addressBreak;
        }
      }
    }

    if (torshavnRegions.includes(parseInt(zip))) {
      region = regionSearch.find((e) => e.searchString === "tórshavn").value;
    }

    // Init whether to update as false
    let update = false;

    // Region was found, set the region and flag for update
    if (region !== null) {
      update = true;
      this.props.newTourOperator.address.region = region;
    }

    // Zip was found, set the zip and flag for update
    if (zip !== null) {
      update = true;
      this.props.newTourOperator.address.zip = zip;
    }

    // If we are to update....
    if (update) {
      this.props.setState({});
    }

    return;
  }

  /**
   * Render the contact information form
   *
   * @returns 		{jsx} 										Contact information form
   *
   * @author 					Pætur Mortensen
   */
  render_contact_form() {
    return (
      <div className="contact-information">
        <p className="contact-header">{this.props.language.place.contact_information}</p>
        <div className="field-row">
          <label>{this.props.language.place.website}</label>
          <div>
            <VfiInputText
              placeholder={this.props.language.place.website_url}
              defaultValue={this.props.newTourOperator.contactDetails.website}
              onChange={(e) => {
                this.props.newTourOperator.contactDetails.website = e.target.value;
                // Flag as "has been edited"
                this.props.setState({
                  beenEdit: {
                    ...this.props.state.beenEdit,
                    contactDetails: {
                      ...this.props.state.beenEdit.contactDetails,
                      website: true,
                    },
                  },
                });
              }}
              errorCheckSequence={[Verify.notEmpty]}
              hideError={!this.props.state.beenEdit.contactDetails.website}
            />
          </div>
        </div>
        <div className="field-row">
          <label>{this.props.language.place.phone_number}</label>
          <div className="phone-input">
            <Select
              className="country-code"
              defaultValue={{ value: 298, label: "+" + 298 }}
              options={this.countryCodes}
              onChange={(e) => {
                this.props.newTourOperator.contactDetails.phoneCC = e.value;
              }}
            />
            <VfiInputText
              className="phone-number"
              placeholder={this.props.language.place.phone_number}
              defaultValue={this.props.newTourOperator.contactDetails.phone}
              onChange={(e) => {
                this.props.newTourOperator.contactDetails.phone = e.target.value;
                // Flag as "has been edited"
                this.props.setState({
                  beenEdit: {
                    ...this.props.state.beenEdit,
                    contactDetails: {
                      ...this.props.state.beenEdit.contactDetails,
                      phone: true,
                    },
                  },
                });
              }}
              errorCheckSequence={[Verify.notEmpty]}
              hideError={!this.props.state.beenEdit.contactDetails.phone}
            />
          </div>
        </div>
        <div className="field-row">
          <label>{this.props.language.place.email}</label>
          <div>
            <VfiInputText
              placeholder={this.props.language.place.email}
              defaultValue={this.props.newTourOperator.contactDetails.email}
              onChange={(e) => {
                this.props.newTourOperator.contactDetails.email = e.target.value;
                // Flag as "has been edited"
                this.props.setState({
                  beenEdit: {
                    ...this.props.state.beenEdit,
                    contactDetails: {
                      ...this.props.state.beenEdit.contactDetails,
                      email: true,
                    },
                  },
                });
              }}
              errorCheckSequence={[Verify.notEmpty, Verify.validEmail]}
              hideError={!this.props.state.beenEdit.contactDetails.email}
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * Render the location form
   *
   * @returns 	{jsx} 								Location form
   *
   * @author 					Pætur Mortensen
   */
  render() {
    const beenEdit = this.props.state.beenEdit;
    // Make sure we're using the correct datatype for the marker
    this.props.newTourOperator.address.mapMarker.lat = parseFloat(this.props.newTourOperator.address.mapMarker.lat);
    this.props.newTourOperator.address.mapMarker.lng = parseFloat(this.props.newTourOperator.address.mapMarker.lng);

    return (
      <div className="new-content six place-section">
        {this.props.whatsonNavigation()}

        <div className="address">
          {/* Form step header */}
          <h1>{this.props.language.place.header}</h1>
          <p>{this.props.language.place.sub_text}</p>

          {/* Location form */}
          <div className="edit-contents">
            <SearchBox
              change={this.onAddressChange}
              onChange={(e) => {
                this.props.newTourOperator.address.name = e;
                this.props.setState({
                  beenEdit: { ...beenEdit, address: true },
                });
              }}
              value={this.props.newTourOperator.address.name}
            />
            <SimpleMap marker={this.props.newTourOperator.address.mapMarker} onClick={(e) => this.onMarkerChange(e)} />

            <div className="more-values row">
              <div className="region col-xl-6">
                <p>{this.props.language.place.region}</p>
                <Select
                  className={beenEdit.address && !this.props.newTourOperator.address.region && "error"}
                  onChange={(e) => {
                    this.props.newTourOperator.address.region = e.value;
                    this.props.setState({
                      beenEdit: { ...beenEdit, address: true },
                    });
                  }}
                  options={this.state.regions}
                  value={
                    this.state.regions[
                      this.state.regions.findIndex(
                        (item) => item.value === this.props.newTourOperator.address.region?.toString()
                      )
                    ]
                  }
                />
              </div>
              <div className="zip-code col-xl-6">
                <p>{this.props.language.place.zip_code}</p>
                <Select
                  className={beenEdit.address && !this.props.newTourOperator.address.zip && "error"}
                  onChange={(e) => {
                    this.props.setState({
                      zipCode: e.value,
                      beenEdit: { ...beenEdit, address: true },
                    });
                    this.props.newTourOperator.address.zip = e.value;
                  }}
                  options={this.zipCodes}
                  value={this.zipCodes.find((e) => e.value === parseInt(this.props.newTourOperator.address.zip))}
                />
              </div>
            </div>
          </div>
        </div>
        {this.render_contact_form()}
      </div>
    );
  }
}

export default Location;
