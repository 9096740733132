import { faArrowLeft, faExternalLink } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import "../shared/SharedPreviewDisplay.scss";
import env from "../../../environment.json";
import axios from "axios";
import GeneralOptions from "./GeneralOptions";
import WhatsonCreateNew from "../WhatsonDisplay/WhatsonCreateNew";
import { useUserStore } from "src/Components/ZustandStores/userStore";

export default function PreviewWhatson({
  whatWhatson,
  whatsonId,
  close = () => void 0,
  openOverlay = () => void 0,
  setSiteRef = () => void 0,
  addOne,
  approvalStatusChange = (id, value) => void 0,
  // keyChange=(id,key,value) => void 0,
  autoSave = (data) => void 0,
}) {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  // const [whatsonUrl, setWhatsonUrl] = useState("");
  const [whatsonSlug, setWhatsonSlug] = useState("");
  // const [whatsonSiteId, setWhatsonSiteId] = useState(undefined);
  const [whatsonSiteInfo, setWhatsonSiteInfo] = useState(undefined);
  const [sideOption, setSideOption] = useState("general");
  const [data, setData] = useState(undefined);

  const [latestGet, setLatestGet] = useState(0);
  const [latestPageContent, setLatestPageContent] = useState(0);
  const [editData, setEditData] = useState(null);
  const middlePath =
    whatWhatson === "event"
      ? "/events/event/"
      : whatWhatson === "hike" || whatWhatson === "hiking"
      ? "/hiking/hike/"
      : whatWhatson === "tour"
      ? "/tours/tour/"
      : whatWhatson === "place"
      ? "/places/place/"
      : whatWhatson === "tour operator"
      ? "/touroperators/touroperator/"
      : "";
  const user = useUserStore();

  const lockedBySessionForEdit =
    !["tour operator", "tour provider"].includes(whatWhatson) &&
    (!data || (data.editSession?.userId && data.editSession?.userId !== user.loggedInUser.userId));

  let completeUrl = "";
  if (whatsonSiteInfo) {
    const { protocol, domain_name } = whatsonSiteInfo;
    completeUrl = protocol + domain_name + middlePath + whatsonSlug;
  }

  useEffect(() => {
    const abortController = new AbortController();

    axios
      .post(
        env.protocol + env.env + "/api/sites/GetSitesBySlug.php",
        {
          sites: ["whatson"],
        },
        { signal: abortController.signal }
      )
      .then((response) => {
        const whatsonSite = response.data.find((e) => e.type_of === "whatson");
        if (whatsonSite !== undefined) {
          // setWhatsonUrl(whatsonSite.protocol + whatsonSite.domain_name);
          // setWhatsonSiteId(whatsonSite.siteId);
          setWhatsonSiteInfo(whatsonSite);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // console.log("Request canceled", error.message);
        } else {
          console.error(error);
        }
      });

    return () => {
      abortController.abort(); // Cancel the request when the component unmounts
    };
  }, []);

  useEffect(() => {
    getWhatson(false, (response) => {
      const language = selectedLanguage === "en" ? "english" : "faroese";
      setWhatsonSlug(response.data.text[language].url_slug);
      setData(response.data);
      setLatestGet(new Date().getTime());
      if (Boolean(response.data.text.english.show_it)) {
        // setSelectedLanguage("en");
      } else {
        setSelectedLanguage("fo");
      }
    });
  }, [selectedLanguage, latestPageContent]);

  const getWhatson = (includeSelectedLang = true, afterFunc = (response) => void 0, setEdit = false) => {
    let apiUrl = env.protocol + env.env + "/api/public/whatson";
    switch (whatWhatson) {
      case "event":
        apiUrl += `/events/GetEvent.php?id=${whatsonId}&for-edit${setEdit ? "&set-edit" : ""}`;
        break;
      case "hike":
      case "hiking":
        apiUrl += `/hiking/GetHiking.php?id=${whatsonId}&for-edit${setEdit ? "&set-edit" : ""}`;
        break;
      case "tour":
        apiUrl += `/tours/GetTour.php?id=${whatsonId}&for-edit${setEdit ? "&set-edit" : ""}`;
        break;
      case "place":
        apiUrl += `/places/GetPlace.php?id=${whatsonId}&for-edit${setEdit ? "&set-edit" : ""}&bypass-lang-show`;
        break;
      case "tour operator":
      case "tour provider":
        apiUrl =
          env.protocol +
          env.env +
          `/api/secured/whatson/touroperators/GetTourOperator?id=${whatsonId}&for-edit${setEdit ? "&set-edit" : ""}`;
        break;
      default:
        apiUrl += "/no-such-whatson";
        break;
    }
    apiUrl += includeSelectedLang ? `&lang=${selectedLanguage}` : "";
    axios(apiUrl)
      .then((response) => {
        afterFunc(response);
      })
      .catch((error) => console.error(error));
  };

  const changeValue = (key, value, inWhatTable) => {
    axios
      .post(env.protocol + env.env + "/api/secured/whatson/ChangeWhatsonValue", {
        user: sessionStorage.getItem("vfiUser"),
        whatWhatson: whatWhatson,
        id: data.data.id,
        lang: inWhatTable === "text" ? (selectedLanguage === "en" ? "english" : "faroese") : "",
        inWhatTable: inWhatTable,
        label: key,
        value: value,
      })
      .then((response) => {
        setLatestPageContent(new Date().getTime());
        // let o = whatsonContentPush({[key]:value});
        // o = Object.fromEntries(Object.entries(o).filter(([_, v]) => v != undefined));
        // keyChange(data.data.id, key, value);
        autoSave(response.data.value);
      })
      .catch((error) => console.error(error));
  };

  if (editData !== null) {
    return (
      <div className="whatson-content">
        <WhatsonCreateNew
          newWhat={whatWhatson}
          updateData={editData}
          pushOneAndGoToList={() => {
            setEditData(null);
            setLatestPageContent(new Date().getTime());
          }}
          updateOneAndGoToList={() => {
            setEditData(null);
            setLatestPageContent(new Date().getTime());
          }}
          // autoSave={this.autoSave}
          autoSave={
            // (value) => {
            //   setEditData(value);
            // }
            autoSave
          }
          openOverlay={openOverlay}
          addOne={addOne}
          // addContent={addContent}
          setSiteRef={() => {
            setSiteRef(whatsonSiteInfo.siteId);
          }}
          language={"english"}
          close={() => {
            setEditData(null);
            setLatestPageContent(new Date().getTime());
          }}
          creatingANew={false}
        />
      </div>
    );
  }

  return (
    <div className="preview-display">
      <div className="left-side">
        <div className="header">
          <div className="header-content">
            <div className="go-back" onClick={close}>
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </div>
            <button
              className={"one-language" + (selectedLanguage === "en" ? " selected" : "")}
              onClick={() => setSelectedLanguage("en")}
              disabled={data && !data.text.english.show_it}
            >
              EN
            </button>
            <button
              className={"one-language" + (selectedLanguage === "fo" ? " selected" : "")}
              onClick={() => setSelectedLanguage("fo")}
              disabled={data && !data.text.faroese.show_it}
            >
              FO
            </button>
          </div>
        </div>
        <div className="display-section-content fill">
          <div className="display-content-top">
            <div className="section-wrap">
              <div
                className={"edit-button" + (lockedBySessionForEdit ? " disabled" : "")}
                onClick={() => {
                  getWhatson(
                    false,
                    (response) => {
                      if (["event"].includes(whatWhatson)) {
                        if (response.data.editSession.userId === user.loggedInUser.userId) {
                          setEditData(response.data);
                        }
                      } else {
                        setEditData(response.data);
                      }
                    },
                    true
                  );
                }}
              >
                EDIT
              </div>
              <div className="locked-info">
                {data?.editSession?.userId
                  ? ` - Locked by ${
                      data.editSession.userId === user.loggedInUser.userId ? "you" : data.editSession.userName
                    } for the next ${Math.ceil(
                      (data.editSession.MAX_AGE - (Date.now() / 1000 - data.editSession.timestamp)) / 60
                    )} minutes`
                  : ""}
              </div>
            </div>
            <a className="external-link" href={completeUrl} target="_blank">
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
          </div>
          <div className="page-content">
            {completeUrl && whatsonSlug && whatsonSiteInfo && (
              <iframe key={latestPageContent} className="iframe" src={completeUrl + "?disabled"} />
            )}
          </div>
        </div>
      </div>
      <div className="right-side">
        <div className="options-section-header">
          <div
            className={"option" + (sideOption === "general" ? " selected" : "")}
            onClick={() => {
              setSideOption("general");
            }}
          >
            General
          </div>
        </div>
        {sideOption === "general" && (
          <GeneralOptions
            key={latestGet + selectedLanguage}
            selectedLanguage={selectedLanguage}
            className="section"
            data={data}
            whatWhatson={whatWhatson}
            changeValue={changeValue}
            onChange={setData}
            approvalStatusChange={approvalStatusChange}
            disabled={lockedBySessionForEdit}
          />
        )}
      </div>
    </div>
  );
}
