import React, { useEffect, useState, useMemo } from "react";
import styles from "./notificationSettings.module.scss";
import Checkbox from "../../../../../Checkbox/Checkbox";
import env from "../../../../../../environment.json";
import axios from "axios";
import { Category, NotificationSettingsProps } from "./types/NotificationSettingsTypes";
import { useUserStore } from "src/Components/ZustandStores/userStore";
import { modulesConfig } from "src/utils";

/**
 * NotificationSettings component displays the user's notification settings
 * and allows them to enable/disable various notification options.
 *
 * @param {NotificationSettingsProps} props - Component properties.
 * @returns {JSX.Element} - Rendered component.
 */
const NotificationSettings: React.FC<NotificationSettingsProps> = ({
  onUpdateNotificationSettings,
  onCheckmarkChange,
  userId,
}) => {
  const { permissions } = useUserStore();
  const [showSettings, setShowSettings] = useState(false);

  // State to hold the fetched notification settings
  const [notificationSettings, setNotificationSettings] = useState<{
    [categoryKey: string]: Category;
  }>({});
  const [initialRender, setInitialRender] = useState(true);

  /**
   * sortObjectKeys sorts the keys of an object according to the provided order.
   *
   * @template T - The type of the input object.
   * @param {T} obj - The input object.
   * @param {string[]} order - The desired order of the keys in the output object.
   * @returns {T} - The sorted object.
   */
  const sortObjectKeys = <T extends object>(obj: T, order: string[]): T => {
    const sortedObject = {} as Partial<T>;

    order.forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        sortedObject[key as keyof T] = obj[key as keyof T];
      }
    });

    for (const key in obj) {
      if (!Object.prototype.hasOwnProperty.call(sortedObject, key)) {
        sortedObject[key as keyof T] = obj[key as keyof T];
      }
    }

    return sortedObject as T;
  };

  useEffect(() => {
    const shouldShow = shouldShowSettings(permissions);
    setShowSettings(shouldShow);
  }, [permissions]);

  // Fetch notification settings once when the component mounts
  useEffect(() => {
    if (!userId || !showSettings) return;

    const fetchNotificationSettings = async () => {
      try {
        const response = await axios.get(
          env.protocol + env.env + "/api/secured/notifications/fetch_notification_settings",
          {
            params: {
              userId: userId,
            },
          }
        );
        const data = response.data;

        if (data.error) {
          console.error("Error fetching notification settings:", data.error);
        } else {
          // Remove the user_has_notification_settings key before setting state
          const { user_has_notification_settings, ...settings } = data;

          setNotificationSettings(settings);
        }
      } catch (error) {
        console.error("Failed to fetch notification settings:", error);
      }
    };

    fetchNotificationSettings();
  }, [userId, showSettings]);

  // useMemo to memoize the sorted notification settings
  const sortedNotificationSettings = useMemo(() => {
    const desiredOrder = ["email", "browser", "adminApp"];
    return sortObjectKeys(notificationSettings, desiredOrder);
  }, [notificationSettings]);

  // Update parent component with the current notification settings
  useEffect(() => {
    if (!initialRender) {
      onUpdateNotificationSettings(notificationSettings);
    } else {
      setInitialRender(false);
    }
  }, [notificationSettings]);

  /**
   * handleCheckmarkChange updates the enabled status of a specific option
   * within a category in the notification settings state.
   *
   * @param {string} category - The category key.
   * @param {string} key - The option key.
   * @param {boolean} value - The new enabled status of the option.
   */
  const handleCheckmarkChange = (category: string, key: string, value: boolean) => {
    setNotificationSettings({
      ...notificationSettings,
      [category]: {
        ...notificationSettings[category],
        options: {
          ...notificationSettings[category].options,
          [key]: {
            ...notificationSettings[category].options[key],
            enabled: value,
          },
        },
      },
    });

    if (!initialRender) {
      onCheckmarkChange();
    }
  };

  if (!modulesConfig.notifications.enabled || !showSettings) return null;

  return (
    <>
      <h1 className={styles.title}>Approval flow notifications</h1>
      <div className={styles.categoriesContainer}>
        {Object.entries(sortedNotificationSettings).map(([categoryKey, category]) => {
          if (categoryKey === "adminApp") {
            return null; // Skip rendering the "adminApp" category
          }
          return (
            <div className={styles.catContainer} key={categoryKey}>
              <div className={styles.catTitle}>{category.display_name}</div>
              <ul>
                {Object.entries(category.options).map(([optionKey, option]) => {
                  return (
                    <li key={`${userId}-${categoryKey}-${optionKey}`}>
                      <Checkbox
                        checked={option.enabled}
                        onChange={(e) => handleCheckmarkChange(categoryKey, optionKey, e.target.checked)}
                      >
                        <span>{option.display_name}</span>
                      </Checkbox>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default NotificationSettings;

type PermissionItem = {
  permission: string;
  permission_value: number;
  id: number;
  role_id: number;
  user_id: number;
};

type Permissions = {
  [key: string]: PermissionItem[];
};

function shouldShowSettings(permissions: Permissions): boolean {
  for (const key in permissions) {
    const permissionArea = permissions[key];
    for (const permissionItem of permissionArea) {
      if (permissionItem.permission_value >= 200) {
        return true;
      }
    }
  }
  return false;
}
